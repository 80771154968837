export const defaultStyle = `
html.theme-light .loading-box.appLoading .lds-default > div {
  background-color: var(--text-color-1,#181b1e);
}

html.theme-light .loading-box .power-text{
  color:#0D0E0F;
}

html.theme-light body{
  background-color: #fff !important;
}

html.theme-dark body{
  background-color: #0D0E0F;
}

.preload-font {
  font-size: 18px;
  opacity: 0;
  position: absolute;
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.preload-font[data-font-weight="100"]{
  font-weight: 100;
}
.preload-font[data-font-weight="300"]{
  font-weight: 300;
}
.preload-font[data-font-weight="400"]{
  font-weight: 400;
}
.preload-font[data-font-weight="500"]{
  font-weight: 500;
}
.preload-font[data-font-weight="700"]{
  font-weight: 700;
}
.preload-font[data-font-weight="900"]{
  font-weight: 900;
}

.theme-light .appLoading .power-text {
  color: #666;
}
.theme-light .appLoading .logo-text {
  color: #000 !important;
}
.loading-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  width: 100vw;
  height: 100vh;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}
.loading-box.appLoading,
.loading-box[data-mask='false'] {
  background: none;
  pointer-events: none;
}
.loading-box .power-text {
  color: #666;
  text-align: center;
  margin-top: 15px;
  font-size: 8px;
}
.loading-box .logo-text {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.loading-box .footer-style {
  margin-top: 30px;
  width: 200px;
}
.loading-box .lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 65px;
  margin-top: 40vh;
  margin-bottom: 20px;
}
.loading-box .lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--text-color-1);
  border-radius: 6px;
  animation: lds-default 1.2s linear infinite;
}
.theme-dark .loading-box .lds-default div{
  background: var(--text-color-1,#fff);
}
.theme-light .loading-box .lds-default div{
  background: var(--text-color-1,#000);
}
.loading-box .lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.loading-box .lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.loading-box .lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.loading-box .lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.loading-box .lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.loading-box .lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.loading-box .lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.loading-box .lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.loading-box .lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.loading-box .lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.loading-box .lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.loading-box .lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
`;
