import Icon from '@ant-design/icons';
import { useSetState } from 'ahooks';
import { memo, useEffect, useMemo, useState } from 'react';
import icons from './svgs/index';

const MIcon = (props: any) => {
  const { name } = props;

  const className = useMemo(() => {
    return [props.className || '', 'micon'].join(' ');
  }, [props.className]);

  const styles = useMemo(() => {
    let data = {
      ...(props.style || {}),
    };
    if (props.size) {
      data = {
        ...data,
        fontSize: props.size + 'px',
      };
    }
    return data;
  }, [props]);

  // @ts-ignore
  const component: any = icons[name];
  if (!component) {
    return null;
  }

  return <Icon component={component} {...props} style={styles} className={className} />;
};

export default memo(MIcon);
