import { chains, ParticleChains, type ChainInfo } from '@particle-network/chains';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { ERC4337Config, logStack } from '@particle-network/wallet-core';
import { message } from 'antd';
import { Chain, createPublicClient, createWalletClient, custom, defineChain, http, PublicClient, WalletClient } from 'viem';
import { isIframe, isServer } from './env-util';
export * from './env-util';

type ConfigChainInfo = {
  id: number;
  name?: string;
  chainType?: 'evm' | 'solana';
};

const publicClients: { [chainId: number]: PublicClient } = {};

export const languages = [
  {
    label: 'English',
    value: 'en-US',
  },
  {
    label: '中文（简体）',
    value: 'zh-CN',
  },
  {
    label: '中文（繁體）',
    value: 'zh-TW',
  },
  {
    label: '한국어',
    value: 'ko-KR',
  },
  {
    label: '日本語',
    value: 'ja-JP',
  },
];

export interface AppParams {
  appId: string;
  clientKey: string;
  projectId: string;
  chainName: string;
  chainId: string | number;
  paramsChainId: string | number;
  loginType: 'session' | 'connect';
  supportChains?: any[] | null;
  theme: string;
  hideSuspiciousTokens: boolean;
  hideSuspiciousNfts: boolean;
  language: string;
  currency: string;
  showTestNetWork?: boolean;
  themeKey?: string | null;
  supportAddToken: boolean;
  defaultChainName: string;
  defaultChainId: string;
  authUserInfo?: any;
  inAuthCoreIframe?: boolean;
  topMenuType?: string;
  displayNFTContractAddresses?: string[];
  priorityTokenAddresses?: string[];
  priorityNFTContractAddresses?: string[];
  displayTokenAddresses?: string[];
  mockEvmAddress?: string;
  fiatCoin?: string;
  mockSolanaAddress?: string;
  customStyle?: any;
  customStyleSetting?: string;
  evmSupportWalletConnect: boolean; // 是否支持wallet connect
  supportUIModeSwitch: boolean; // 是否支持切换dark/light模式
  supportLanguageSwitch: boolean; // 是否支持切换语言
  authTheme?: any;
  erc4337?: ERC4337Config;
  supportBridgeChains: number[];
  authType: string | null;
  authSDKVersion: string | null;
  supportCustomEvent?: boolean;
}

export const currencyMap = {
  USD: ['$'],
  CNY: ['¥'],
  JPY: ['¥'],
  HKD: ['HK$'],
  INR: ['₹'],
  KRW: ['₩'],
};

export const subString = (str: string = '', start: number = 5, end: number = 5) => {
  // if (str.length > len) {
  return str.substring(0, start) + '...' + str.substring(str.length - end, str.length);
  // } else {
  //   return str;
  // }
};

export const errorHandle = (error: any) => {
  console.log('err', error);
  console.log('errorHandle Stack trace:', error.stack);

  let messageText = error?.data?.extraMessage?.message || error?.data?.extraMessage || error?.cause?.message || error?.shortMessage || error?.details || error?.message || 'Network Error Code:5006';

  const { chainId, chainName, address } = window.__webWalletData || {};
  logStack(chainId, chainName, address, error);

  if (['The user rejected the request', 'The user cancel the operation', 'gasLimit too low', 'extraMessage'].find((val) => messageText.includes(val))) {
    return false;
  } else if (['User not login', 'Your session has expired'].find((val) => messageText.includes(val))) {
    message.error(messageText);
    if (!isIframe()) {
      setTimeout(() => {
        window.__webWalletData.logout();
      }, 300);
    }
    return false;
  } else if (messageText.includes('Transaction cancelled')) {
    return;
  }
  if (messageText.length > 130) {
    messageText = messageText.substring(0, 130) + '...';
  }
  if (messageText?.trim?.()) {
    message.error(messageText);
  }
  return true;
};

export const getCurrencySymbol = (currency: string) => {
  // @ts-ignore
  const symbol = currencyMap[currency.toUpperCase()];
  return symbol ? symbol[0] : '$';
};

export const disableZoom = () => {
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  var lastTouchEnd = 0;
  document.addEventListener(
    'touchend',
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault();
  });
};

export const setProperty = (key: string, value: any) => {
  if (!isServer()) {
    window.document.documentElement.style.setProperty(key, value);
  }
};

export function popupWindow(url: string, title: string, w: number, h: number): Window | null {
  const left = screen.width / 2 - w / 2;
  const top = screen.height / 2 - h / 2;
  return window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
  );
}

/**
 * 根据ParticleChains检查链是否有效
 * @param name
 * @param id
 */
export const checkChainValid = (name: string, id: number): boolean => {
  if (!name || !id) {
    return false;
  }

  const chain = Object.values(ParticleChains).find((item) => item.name === name && item.id == id);

  return Boolean(chain);
};

export const ipfsToSrc = (ipfs?: string) => {
  if (!ipfs?.startsWith('ipfs://')) {
    return ipfs ?? '';
  }

  const encodedPath = encodeURI(ipfs.slice(7));
  return `https://ipfs.particle.network/${encodedPath}`;
};

export const isMobile = () => {
  if (isServer()) return false;
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
};

export const isSmallScreen = () => {
  if (isServer()) return false;
  return window.innerWidth < 768;
};

export const errorToString = (err: any): string => {
  if (typeof err === 'string') {
    return err;
  } else if (typeof err === 'object' && err.message) {
    return err.message;
  } else {
    return err?.toString() ?? 'System Error';
  }
};

export const isIos = () => {
  if (isServer()) return false;
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};

export const disableBrowserZoom = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    document.body.style.zoom = 'reset';

    document.addEventListener(
      'keydown',
      function (event) {
        if (
          (event.ctrlKey === true || event.metaKey === true) &&
          (event.which === 61 || event.which === 107 || event.which === 173 || event.which === 109 || event.which === 187 || event.which === 189)
        ) {
          event.preventDefault();
        }
      },
      false
    );

    document.addEventListener(
      'mousewheel DOMMouseScroll',
      function (event) {
        // @ts-ignore
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      false
    );
  }
};

export const maxZIndex = (): number => {
  const zIndexList = Array.from(document.querySelectorAll('*')).map((a) => {
    return Number(window.getComputedStyle(a)?.zIndex || 0) || 0;
  });
  return Math.max(...zIndexList) || 0;
};

// 获取当前系统默认语言
export const getNavigatorLanguage = () => {
  if (isServer()) return 'en-US';
  let language = navigator.language;
  language = languages?.find?.((item) => item.value.toLowerCase() == language?.toLowerCase())?.value || 'en-US';
  return language;
};

// 驼峰转中划线
export const camelToLine = (name: string) => {
  return name.replace(/([A-Z])/g, '-$1').toLowerCase();
};

export const setViewHeight = () => {
  let n = document;
  let e = window;
  function fn() {
    var windowVH = e.innerHeight / 100;
    setProperty('--vh', windowVH + 'px');
  }
  var i = 'orientationchange' in window ? 'orientationchange' : 'resize';
  n.addEventListener('DOMContentLoaded', fn);
  e.addEventListener(i, fn);
};

export const getAllLocalStorage = () => {
  const result: any = {};
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) {
      result[key] = localStorage.getItem(key) || '';
    }
  }
  return result;
};

// export const getAllSessionStorage = () => {
//   const result: any = {};
//   for (let i = 0; i < sessionStorage.length; i++) {
//     const key = sessionStorage.key(i);
//     if (key) {
//       result[key] = window.storage.getItem(key);
//     }
//   }
//   return result;
// };

export const sleep = (time = 300) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

// @ts-ignore
String.prototype.format = function (...params: string[]): string {
  const args = params;
  return this.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? args[number] : match;
  });
};

// 23/02/2023 11:25:12 => 2023-02-23 11:25:12
export const formatTime = (time: string = '') => {
  const [date = '', timeStr = ''] = time.split(' ');
  const [day = '', month = '', year = ''] = date.split('/');
  return `${year}-${month}-${day} ${timeStr}`.replace(/^\-\-/, '');
};

export function captureMessage(title: string, params: any, messageType = 'info') {
  try {
    // @ts-ignore
    window.Sentry &&
      // @ts-ignore
      window.Sentry.withScope((scope) => {
        scope.setLevel(messageType);
        scope.setExtra('params', params);
        // @ts-ignore
        const res = window.Sentry.captureMessage(title, {
          level: messageType,
          fingerprint: [title],
        });
        console.log('res', res);
      });
  } catch (error) {
    console.log(error);
  }
}

// 页面滚动时，检测光标是否在输入框内，如果在输入框内，让该输入框失去焦点
export function scrollBlurInput(scrollDom: HTMLElement): () => void {
  function handleScroll() {
    const activeElement = document.activeElement as HTMLInputElement | undefined;
    if (activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA' || activeElement?.classList.contains('div-input')) {
      const { scrollTop } = scrollDom;
      activeElement.blur?.();
      scrollDom.scrollTop = scrollTop;
    }
  }

  scrollDom.addEventListener('scroll', handleScroll);

  return function cleanup() {
    scrollDom.removeEventListener('scroll', handleScroll);
  };
}

export const getSecurityMethod = (fn?: any) => {
  return new Promise(async (resolve) => {
    let flag = true;
    do {
      // @ts-ignore
      const webWalletData = window.__webWalletData;
      if (
        webWalletData.particleWallet &&
        webWalletData.chainInfo &&
        webWalletData.chainInfo.chainType === webWalletData.particleWallet.chainType &&
        Number(webWalletData.chainInfo.chainId) == Number(webWalletData.particleWallet.chainId) &&
        webWalletData.chainInfo.chainName.toLowerCase() === webWalletData.particleWallet.chainName.toLowerCase()
      ) {
        flag = false;
      } else {
        await sleep(10);
      }
    } while (flag);
    setTimeout(() => {
      resolve(fn);
    });
  });
};

export const getCacheData = async () => {
  let dbDatas: any = {};

  try {
    // @ts-ignore
    const database: any = __webWalletData.particleWallet.database;
    const storeNames = database._storeNames;
    for (let i = 0; i < storeNames.length; i++) {
      dbDatas[storeNames[i]] = await database[storeNames[i]].toArray();
    }
  } catch (error) {
    console.log('getCacheData error', error);
  }

  const cacheData = {
    dbDatas,
    sessionStorage: Object.keys(sessionStorage).map((key) => [key, sessionStorage[key]]),
    localStorage: Object.keys(localStorage).map((key) => [key, localStorage[key]]),
    url: location.href,
  };
  const encodeCacheData = encodeURIComponent(JSON.stringify(cacheData));
  const cacheDataBase64 = Buffer.from(encodeCacheData).toString('base64');
  const a = document.createElement('a');
  a.href = `data:text/plain;charset=utf-8,${cacheDataBase64}`;
  a.download = `record-${new Date().toLocaleString().replace(/\//g, '-').replace(/:/g, '-')}.log`;
  a.click();
};

/**
 * setCacheData
 * @param {string} refresh 是否刷新页面
 */
export const setCacheData = async (encodeCacheData: string, refresh = false) => {
  encodeCacheData = Buffer.from(encodeCacheData, 'base64').toString('utf-8');
  const { sessionStorage: sessionStorageList, localStorage: localStorageList, url, dbDatas } = JSON.parse(decodeURIComponent(encodeCacheData));
  sessionStorageList.forEach((item: any) => {
    sessionStorage.setItem(item[0], item[1]);
  });
  localStorageList.forEach((item: any) => {
    localStorage.setItem(item[0], item[1]);
  });

  try {
    if (dbDatas) {
      // @ts-ignore
      const database: any = __webWalletData.particleWallet.database;
      const storeNames = database._storeNames;
      for (let i = 0; i < storeNames.length; i++) {
        try {
          database[storeNames[i]].clear();
          database[storeNames[i]].bulkAdd(dbDatas[storeNames[i]]);
        } catch (error) {
          console.log('setCacheData error', error);
        }
      }
    }
  } catch (error) {
    console.log('setCacheData error', error);
  }

  if (refresh && url) {
    const targetUrl = location.origin + location.pathname + '?' + url.split('?').pop();
    console.log('targetUrl', targetUrl);
    location.href = targetUrl;
  }
};

/**
 * 异步加载cdn js，如果加载过就不重复加载 返回 Promise
 * @param url
 * @returns
 */
export function loadScript(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${url}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = url;
    // Resolve the Promise when the script has loaded
    script.addEventListener('load', () => {
      resolve();
    });

    script.addEventListener('error', () => {
      reject(new Error(`Failed to load script ${url}`));
    });

    document.head.appendChild(script);
  });
}

export function convertToBoolean(value: any) {
  if (typeof value === 'boolean') {
    return value;
  } else if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  } else {
    return !!value;
  }
}

export const isIndexPage = () => {
  if (!isServer()) {
    const pathname = location.pathname;
    return pathname === '' || pathname === '/' || pathname === '/index.html' || pathname === '/index';
  } else {
    return false;
  }
};

/**
 *
 * @param selector
 * @returns
 */
export function waitForElement(selector: string): Promise<HTMLElement> {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      const element = document.querySelectorAll(selector);
      if (element.length > 0) {
        clearInterval(intervalId);
        resolve(element[0] as HTMLElement);
      }
    }, 5);
  });
}

/**
 * walletconnect iocn
 */
export const getWalletConnectIcon = (name: string) => {
  switch (name) {
    case 'Linea POH':
      return 'https://linea.build/favicon.ico';
    default:
      return '';
  }
};

export const getUserInfo = async () => {
  const webWalletData = window.__webWalletData;
  if (webWalletData.inAuthCoreIframe && (webWalletData.supportCustomEvent === true || webWalletData.supportCustomEvent === 'auth')) {
    return await iframeWallet.getUserInfo();
  } else if (webWalletData.inAuthCoreIframe && !webWalletData.supportCustomEvent) {
    return {};
  } else {
    return window.particle?.auth.getUserInfo() || {};
  }
};

export const getViemChain = (chainId: number) => {
  const chain = chains.getEVMChainInfoById(Number(chainId));
  if (!chain) {
    throw new Error(`The chain id ${chainId} is not supported.`);
  }

  return defineChain({
    id: chain.id,
    name: chain.fullname,
    nativeCurrency: chain.nativeCurrency,
    network: chain.network,
    rpcUrls: {
      public: {
        http: [chain.rpcUrl],
      },
      default: {
        http: [chain.rpcUrl],
      },
    },
    blockExplorers: {
      default: {
        name: 'Explorer',
        url: chain.blockExplorerUrl,
      },
    },
  });
};

export const getWalletClient = (provider: any, chain?: Chain): WalletClient => {
  return createWalletClient({
    transport: custom(provider),
    chain,
  });
};

export const walletSwitchChain = async (provider: any, chainId: number) => {
  const chain = getViemChain(chainId);
  console.log('chain', chain);

  const walletClient = getWalletClient(provider, chain);
  if (!walletClient) {
    throw new Error('Please connect wallet first!');
  }
  const currentChainId = await walletClient.getChainId();
  if (currentChainId === chainId) {
    return;
  }
  try {
    await walletClient.switchChain({
      id: chainId,
    });
  } catch (error: any) {
    console.log('walletSwitchChain error', error, error?.message, error?.details);

    if (error?.code === 4902 || error?.message?.includes('Unrecognized chain ID') || error?.details?.includes('Unrecognized chain ID')) {
      try {
        console.log('Switch chain failed, add chain', chain);
        await walletClient.addChain({
          chain,
        });
      } catch (error: any) {
        console.log('addChain error', error, error?.message, error?.details);
        throw new Error(error?.details || error?.message);
      }
    } else {
      if (error?.details || error?.message) {
        throw new Error(error?.details || error?.message);
      } else {
        throw error;
      }
    }
  }
};

export const getPublicClient = (chainId: number): PublicClient => {
  if (publicClients[chainId]) {
    return publicClients[chainId];
  }
  const client = createPublicClient({
    transport: http(`${process.env.NEXT_PUBLIC_RPC_URL}/evm-chain?chainId=${chainId}&projectUuid=${process.env.NEXT_PUBLIC_PROJECT_ID}&projectKey=${process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY}`),
  });

  publicClients[chainId] = client;
  return client;
};

export interface CloudflareOptions {
  getContainer?: HTMLElement | (() => HTMLElement) | false;
  theme?: 'light' | 'dark';
  language?: string;
}

/**
 *  get cloudflare turnstile response
 */
export const getCloudflareTurnstileResponse = async (options?: CloudflareOptions): Promise<string> => {
  const iframe = document.createElement('iframe');
  const theme = 'light';
  const language = options?.language || 'en';
  iframe.src = `https://core.particle.network/cloudflare.html?language=${language}&theme=${theme}&_=0.1.1&siteKey=0x4AAAAAAAaHm6FnzyhhmePw`;

  iframe.classList.add('particle-cloudflare-iframe');

  const style: Record<string, string> = {
    position: 'fixed',
    zIndex: '999999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    background: 'none',
    width: '300px',
    height: '65px',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    colorScheme: 'none',
  };

  for (const key in style) {
    iframe.style[key as any] = style[key];
  }

  const container = options?.getContainer ? (typeof options.getContainer === 'function' ? options.getContainer() : options.getContainer) : document.body;

  container.appendChild(iframe);

  return new Promise((resolve, reject) => {
    const listener = (event: MessageEvent) => {
      if (event.data.type === 'particle-auth-core-cloudflareToken') {
        window.removeEventListener('message', listener);
        container.removeChild(iframe);
        const token = event.data.token;
        if (token) {
          resolve(event.data.token);
        } else {
          reject('Cloudflare verification failed');
        }
      }
    };
    window.addEventListener('message', listener);
  });
};

export function isCurrentVersionLower(currentVersion: string, targetVersion: string) {
  const cVersion = currentVersion.split('.').map(Number);
  const tVersion = targetVersion.split('.').map(Number);

  const length = Math.max(cVersion.length, tVersion.length);

  for (let i = 0; i < length; i++) {
    const cNum = cVersion[i] || 0;
    const tNum = tVersion[i] || 0;

    if (cNum < tNum) {
      return true;
    }
    if (cNum > tNum) {
      return false;
    }
  }
  return false;
}

export const formatChains = (list: ConfigChainInfo[] | null): ChainInfo[] | null => {
  if (!list) return null;

  const validChains: ChainInfo[] = [];

  for (const item of list) {
    const { chainType, id, name } = item;
    let chainInfo: ChainInfo | undefined;

    if (chainType && id) {
      if (chainType === 'evm') {
        chainInfo = chains.getEVMChainInfoById(id);
      } else if (chainType === 'solana') {
        chainInfo = chains.getSolanaChainInfoById(id);
      }
    } else if (id) {
      chainInfo = chains.getEVMChainInfoById(id);
      if (!chainInfo) {
        chainInfo = chains.getSolanaChainInfoById(id);
      }
    }

    if (chainInfo && checkChainValid(chainInfo.name, chainInfo.id)) {
      validChains.push(chainInfo);
    }
  }

  return validChains;
};
