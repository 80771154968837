import { chatLogout } from '@/components/Chat';
import { DidToAddressState } from '@/components/didPanel/config';
import Loading from '@/components/loading';
import { parseAppParams } from '@/hooks/useAppParams';
import useCommon from '@/hooks/useCommon';
import useUniversaActivityList from '@/hooks/useUniversaActivityList';
import useUniversalTokens from '@/hooks/useUniversalTokens';
import { AppParams, getAllLocalStorage, getPublicClient, getUserInfo, getWalletClient, isIframe, isServer, walletSwitchChain } from '@/utils/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AAWrapProvider, SendTransactionEvent, SendTransactionMode } from '@particle-network/aa';
import { CurrencyUnit, ParticleNetwork, UIMode, toBase58Address } from '@particle-network/auth';
import { Solana, SolanaDevnet, SolanaTestnet, chains } from '@particle-network/chains';
import { useConnectId, useConnectKit, useConnectModal, useLanguage, useModalState, useParticleConnect, useParticleTheme, useSwitchChains } from '@particle-network/connect-react-ui';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { ParticleProvider } from '@particle-network/provider';
import { ChainInfo, ERC4337Config, EvmService, SolanaService, getChainList, getParticleChainInfo, switchChain } from '@particle-network/wallet-core';
import { PeerInfo, SwitchChainEvent, WC2Manager } from '@particle-network/walletconnect';
import useResizeObserver from '@react-hook/resize-observer';
import { useDebounceEffect, useDebounceFn, useDeepCompareEffect, useSetState, useTrackedEffect } from 'ahooks';
import { Modal, message } from 'antd';
import { EventEmitter } from 'events';
import { i18n } from 'i18next';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import qs from 'qs';
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { keepAliveDropCache } from 'react-next-keep-alive';
import { PublicClient, WalletClient, toBytes } from 'viem';
import { PageType } from '../types';
import { useUniversalAccount } from './UniversalAccountContext';

const defaultChainName = 'Ethereum';
const defaultChainId = '1';
const defaultCurrency = 'USD';
const defaultTheme = 'dark';

type ViewType = 'desktop' | 'mobile';

const whiteList = ['/404', '/buy.html', '/buy', '/buy2', '/buyList', '/paymenteType'];

interface IChainInfo extends ChainInfo {
  fullname?: string;
  chainType?: 'evm' | 'solana' | undefined;
}

export enum SwtichNetworkStatus {
  init,
  loading,
  success,
  fail,
}

export interface WebWalletData {
  walletCoreVersion: string;
  appId: string;
  projectId: string;
  clientKey: string;
  isLogin: boolean;
  pages?: any;
  userInfo: any;
  address: string;
  btcAddress: string;
  evmUnit: string;
  trxAddress?: string;
  copyAddress: string;
  omitAddress: string;
  particleWallet: EvmService | SolanaService;
  exchangeRate: number;
  balance: number;
  language?: string;
  showTestNetWork: boolean;
  chainName: string;
  chainId: string;
  swtichNetwork: SwtichNetworkStatus;
  swtichNetworkHistory: any[];
  useRequestCache: boolean;
  t: any;
  clearPageCache: (pageName: string) => void;
  chainInfo: IChainInfo;
  currency: string;
  theme: UIMode;
  viewType: ViewType;
  supportChains?: any[] | null;
  debug?: boolean;
  isMockAddress?: boolean;
  displayLogoutButton: boolean;
  events: EventEmitter;
  displayScan: boolean;
  evmSupportWalletConnect: boolean;
  wc2Manager?: WC2Manager;
  loginType: 'session' | 'connect' | '';
  displayNFTContractAddresses?: string[];
  priorityTokenAddresses?: string[];
  priorityNFTContractAddresses?: string[];
  displayTokenAddresses?: string[];
  topMenuType?: string;
  showPasswordRedDot?: boolean;
  hideSuspiciousTokens: boolean;
  hideSuspiciousNfts: boolean;
  displayAccountAndSecurityMenu?: boolean;
  isBtcConnect: boolean;
  isUniversal?: boolean;
  logout: (params?: any) => void;
  accountAbstraction?: ERC4337Config;
  isAA: boolean;
  pageLoading: {
    spinning?: boolean;
    displayMask?: boolean;
    show?: boolean;
  };
  supportBridgeChains: number[];
  inAuthCoreIframe?: boolean;
  showBraveShieldModal?: boolean;
  getWalletClient: (provider: any) => WalletClient;
  getPublicClient: (chainId: number) => PublicClient;
  walletSwitchChain: (provider: any, chainId: number) => void;
  toBytes: any;
  supportCustomEvent?: boolean | 'auth' | 'passkey';
  walletProvider?: any;
  publicProvider?: any;
}

interface WalletconnectData {
  session?: PeerInfo;
  chainId?: string;
  displaySessionRequest?: boolean;
  displaySwitchChain?: boolean;
  peerMeta?: any;
}

type useWebWalletType = () => {
  webWalletData: WebWalletData;
  updateWebWalletData: (data: any) => void;
  updatePageData: (type: string, data: any) => void;
  walletconnectData: WalletconnectData;
  updateWalletconnectData: (data?: WalletconnectData) => void;
  t: TFunction<'translation', undefined>;
  setDidToAddresses: (data: Record<string, DidToAddressState>) => void;
  didToAddresses: Record<string, DidToAddressState>;
  setBtcToAddresses: (data: Record<string, DidToAddressState>) => void;
  btcToAddresses: Record<string, DidToAddressState>;
};

const defaultData: WebWalletData = {
  walletCoreVersion: require('@particle-network/wallet-core/package.json').version,
  particleWallet: {} as EvmService | SolanaService,
  appId: '',
  projectId: '',
  clientKey: '',
  isLogin: false,
  pages: {},
  userInfo: {},
  address: '',
  btcAddress: '',
  evmUnit: '',
  trxAddress: '',
  copyAddress: '',
  omitAddress: '',
  exchangeRate: 0,
  balance: -1,
  showTestNetWork: false,
  chainName: '',
  chainId: '',
  swtichNetwork: SwtichNetworkStatus.init,
  swtichNetworkHistory: [],
  useRequestCache: false,
  t: {},
  language: '',
  clearPageCache: (pageName) => {},
  chainInfo: {} as ChainInfo,
  currency: defaultCurrency,
  theme: defaultTheme,
  viewType: 'desktop',
  debug: false,
  supportChains: [],
  isMockAddress: false,
  displayScan: false,
  evmSupportWalletConnect: false,
  wc2Manager: undefined,
  displayLogoutButton: true,
  events: new EventEmitter(),
  loginType: '',
  showPasswordRedDot: false,
  hideSuspiciousTokens: true,
  hideSuspiciousNfts: true,
  displayAccountAndSecurityMenu: false,
  pageLoading: {},
  isBtcConnect: false,
  isUniversal: false,
  logout: (params?: any) => {},
  isAA: false,
  accountAbstraction: isServer() ? false : JSON.parse(window.storage.getItem('accountAbstractionSwitchConfig') ?? 'false'),
  supportBridgeChains: [],
  inAuthCoreIframe: false,
  getPublicClient,
  getWalletClient,
  walletSwitchChain,
  toBytes,
};

const WalletContent = createContext({});

export let pn: ParticleNetwork | null;

const initWC2Manager = async (evmProvider: any, particleWallet: EvmService, updateWalletconnectData: any, events: EventEmitter, accountAbstraction?: ERC4337Config): Promise<WC2Manager> => {
  console.log('WC2Manager init, erc4337', accountAbstraction && particleWallet.enableAccountAbstraction());

  let wcProvider = evmProvider;
  if (accountAbstraction && particleWallet.enableAccountAbstraction()) {
    // ERC4337 get accounts
    const provider = new AAWrapProvider(particleWallet.smartAccount!, SendTransactionMode.UserSelect);
    wcProvider = provider;
    const onTransactionRequest = (params: any) => {
      events.removeAllListeners('erc4337:sendTransaction');
      events.removeAllListeners('erc4337:sendTransactionError');
      events.once('erc4337:sendTransaction', (params: any) => {
        const { feeQuote, userOpHash, userOp, tokenPaymasterAddress } = params;
        wcProvider.resolveSendTransaction({ feeQuote, userOpHash, userOp, tokenPaymasterAddress });
      });
      events.once('erc4337:sendTransactionError', (error: any) => {
        wcProvider.rejectSendTransaction(error);
      });
      events.emit('erc4337:prepareTransaction', params);
    };

    wcProvider.on(SendTransactionEvent.Request, onTransactionRequest);
  }

  const wc2Manager = await WC2Manager.init({
    projectId: '106b8cf77e7ace62068ed12bcc56e48c',
    provider: wcProvider,
  });

  // @ts-ignore
  wc2Manager.provider = wcProvider;

  wc2Manager.events.removeAllListeners(SwitchChainEvent.request);
  wc2Manager.events.on(SwitchChainEvent.request, (options) => {
    const chainId = Number(particleWallet.chainId);
    console.log(`WalletConnect SwitchChain, from: ${chainId}, to: ${Number(options.chainId)}`);
    if (Number(options.chainId) === chainId) {
      wc2Manager.approveSwitchChain(options.session.peerId);
    } else {
      const chain = particleWallet.getChainById(options.chainId);
      console.log('WalletConnect SwitchChain, chain:', chain);

      if (
        chain &&
        // @ts-ignore wallet supportedChains ,显示的链列表里面有此链
        window.supportedChains?.find((item) => Number(item.chainId) === chain.id)
      ) {
        updateWalletconnectData({
          session: options.session,
          chainId: options.chainId,
          displaySessionRequest: false,
          displaySwitchChain: true,
        });
      } else {
        wc2Manager.rejectSwitchChain(options.session.peerId);
      }
    }
  });
  return wc2Manager;
};

export const useWebWallet: useWebWalletType = () => useContext(WalletContent) as any;
interface IProps {
  appParams: AppParams;
  updateAppParams: (params: any) => void;
  i18n: i18n;
  children: ReactNode;
}

export const WebWalletContextProvider = (props: IProps) => {
  const { appParams, updateAppParams, i18n } = props;

  const { t } = useTranslation();

  const router = useRouter();

  const { disconnect, cacheconnect } = useParticleConnect();

  const { openConnectModal } = useConnectModal();

  const { setTheme: setProviderTheme } = useParticleTheme();

  const { connectModalOpen } = useModalState();

  const connectKit = useConnectKit();

  const { language, changLanguage } = useLanguage();

  const { isSwtichChain, renderChains } = useSwitchChains();

  const connectId = useConnectId();

  const [webWalletData, setWebWalletData] = useSetState(defaultData);

  const [didToAddresses, setDidToAddresses] = useSetState<Record<string, DidToAddressState>>({});

  const [btcToAddresses, setBtcToAddresses] = useSetState<Record<string, DidToAddressState>>({});

  const [appLoading, setAppLoading] = useState(true);

  const { selectedGlobalChain } = useUniversalAccount();

  useUniversaActivityList();
  useUniversalTokens();

  const { run: authCoreSwitchChain } = useDebounceFn(
    (chainId: number) => {
      const { id, name } = chains.getEVMChainInfoById(chainId as number) || {};
      if (Number(id) !== Number(webWalletData.chainId) && (!appParams.supportChains || (appParams.supportChains && appParams.supportChains.find((item) => item.id == Number(id))))) {
        updateWebWalletData({
          chainId: id,
          chainName: name,
          swtichNetwork: SwtichNetworkStatus.loading,
        });
      }
    },
    {
      wait: 10,
    }
  );

  const { particleProvider, particleWalletService, walletAddress: account, getParticleWalletService } = useCommon(appParams, webWalletData, authCoreSwitchChain);

  const [walletconnectData, setWalletconnectData] = useSetState<WalletconnectData>({
    displaySessionRequest: false,
    displaySwitchChain: false,
  });

  // updateWebWalletData
  const updateWebWalletData = (val: any) => {
    // if (!val.pages) {
    //   console.log('>>>> e1 updateWebWalletData', val, Math.random());
    // }
    setWebWalletData((_) => {
      return val;
    });
  };

  // updatePageData  页面数据缓存
  const updatePageData = (pageName: string, val: any) => {
    webWalletData.pages[pageName] = val;
    updateWebWalletData({
      pages: webWalletData.pages,
    });
  };

  // updateWalletconnectData
  const updateWalletconnectData = (data?: WalletconnectData) => {
    if (data) {
      setWalletconnectData(data);
    } else {
      setWalletconnectData({
        displaySessionRequest: false,
        displaySwitchChain: false,
      });
    }
  };

  const updateLogoutButton = () => {
    return !appParams?.authUserInfo?.uuid;
  };

  const updateChainInfo = (testNetWork = true, chainId: string, chainName: string) => {
    const chainInfo = (getChainList(testNetWork, chainId, chainName).find((item: ChainInfo) => item.selected) || {}) as ChainInfo;
    window.storage.setItem('chainInfo', JSON.stringify(webWalletData.chainInfo));
    window.storage.setItem('chainId', webWalletData.chainId.toString());
    window.storage.setItem('chainName', webWalletData.chainName);
    return chainInfo;
  };

  const logout = useCallback(async (params?: any) => {
    try {
      window.logoutFlag = true;
      chatLogout();
      try {
        // @ts-ignore
        await window.__webWalletData?.wc2Manager?.removeAll();
      } catch (error) {
        console.log('wc2Manager removeAll', error);
      }

      try {
        await disconnect({ hideLoading: true });
      } catch (error) {
        console.log('disconnect', error);
      }

      // @ts-ignore
      window?.Sentry?.setUser?.(null);

      // clear session
      // window.storage.clear();

      // getAll Local Storage
      const cacheData = getAllLocalStorage();
      const cacheDataKeys = Object.keys(cacheData);

      // remove storage.removeItem
      [
        'chainId',
        'chainInfo',
        'chainName',
        'mockEvmAddress',
        'mockSolanaAddress',
        'authUserInfo',
        'customStyle',
        'supportUIModeSwitch',
        'accountAbstractionSwitchConfig',
        'supportChains',
        'supportUIModeSwitch',
        'displayNFTContractAddresses',
        'priorityNFTContractAddresses',
        'priorityTokenAddresses',
        'displayTokenAddresses',
        'supportAddToken',
        ...cacheDataKeys.filter((key) => key.includes('wc@')),
      ].forEach((key) => {
        cacheDataKeys.forEach((cacheKey) => {
          if (cacheKey && cacheKey.includes(key)) {
            window.storage.removeItem(cacheKey);
          }
        });
      });

      setTimeout(() => {
        if (isIframe()) {
          console.log('particle wallet post logout message');
          window.parent.postMessage(
            {
              name: 'particle-network-wallet',
              data: {
                type: 'logout',
              },
            },
            '*'
          );
        } else {
          window.location.replace('/');
        }
      }, 200);
    } catch (error: any) {
      console.log('err', error);
      message.error(error.message);
    }
  }, []);

  /**
   * 显示 Brave shield modal
   */
  const showBraveShieldModal = () => {
    Modal.confirm({
      title: 'Please disable Brave Shield to use the wallet.',
      wrapClassName: 'ant-modal-top',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // 设置全局变量 测试使用
  if (!isServer()) {
    // @ts-ignore
    window.__updateWebWalletData = updateWebWalletData;
    // @ts-ignore
    window.__router = router;
    // @ts-ignore
    window.__connectKit = connectKit;
    // @ts-ignore
    window.__particleProvider = particleProvider;
    // @ts-ignore
    window.__account = account;
    // @ts-ignore
    window.__webWalletData = webWalletData;
    // @ts-ignore
    window.__openConnectModal = openConnectModal;
    // @ts-ignore
    window.__updateWalletconnectData = updateWalletconnectData;
    // @ts-ignore
    window.__renderChains = renderChains;
    // @ts-ignore
    window.__isSwtichChain = isSwtichChain;
    // @ts-ignore
    window.__connectId = connectId;
    // @ts-ignore
    window.__disconnect = disconnect;
    // @ts-ignore
    window.__ParticleNetwork = ParticleNetwork;
    // @ts-ignore
    window.__switchChain = switchChain;
    // @ts-ignore
    // window.__biKit = biKit;
    // changLanguage
    // @ts-ignore
    window.__changLanguage = changLanguage;
    // language
    // @ts-ignore
    window.__language = language;
    // @ts-ignore
    window.__i18n = i18n;
    // @ts-ignore
    window.__t = t;
    // @ts-ignore
    window.t = t;
    // @ts-ignore
    window.__message = message;
    // @ts-ignore
    window.__iframeWallet__ = iframeWallet;
  }

  // 如果是通过链接进入的，需要等待链接参数获取到后再渲染页面
  useEffect(() => {
    if (router.query.through) {
      setTimeout(() => {
        setAppLoading(false);
      });
    }
  }, [router.pathname, router.query?.through]);

  // App 初始化
  useEffect(() => {
    webWalletData.clearPageCache = (pageName) => {
      keepAliveDropCache(pageName);
      updatePageData(pageName, {});
    };

    const loginType = appParams?.authUserInfo?.uuid ? 'session' : 'connect';

    const debug = location.href.includes('debug=true');

    let isBtcConnect = (appParams?.erc4337 as any)?.name?.toLowerCase?.() === 'btc';

    // @ts-ignore
    let isUniversal = (appParams?.erc4337 as any)?.name?.toLowerCase?.() === 'universal' || webWalletData?.accountAbstraction.name?.toLowerCase?.() === 'universal';

    Object.assign(webWalletData, cloneDeep(appParams), { logout, loginType, debug, isBtcConnect, isUniversal });

    updateWebWalletData(webWalletData);

    if (appParams.loginType === 'session') {
      // params erc4337 start
      const erc4337 = appParams?.erc4337;
      if (erc4337) {
        window.storage.setItem('accountAbstractionSwitchConfig', JSON.stringify(erc4337));
        updateWebWalletData({
          accountAbstraction: erc4337,
        });
      } else {
        window.storage.setItem('accountAbstractionSwitchConfig', 'false');
        updateWebWalletData({
          accountAbstraction: false,
        });
      }
      // params erc4337 end
    }

    updateLogoutButton();

    // @ts-ignore
    window.__switchTheme = () => {
      // @ts-ignore
      let theme = window.__webWalletData.theme == 'light' ? 'dark' : 'light';
      // @ts-ignore
      window.__updateWebWalletData({ theme });
    };

    // 获取redirect参数，如果有，需要跳转到对应的页面 start
    const query = qs.parse(location.search?.replace?.(/^\?/, '') || '');

    if (!query.redirect) {
      setAppLoading(false);
    } else {
      const arr = ((query.redirect as string) || '')?.split?.('?');
      const pathname = arr?.[0] || '';
      const newQuery = {
        ...qs.parse(arr?.[1] || ''),
        through: true,
      };
      router.push({
        pathname,
        query: newQuery,
      });
    }
    // 获取redirect参数，如果有，需要跳转到对应的页面 end
  }, []);

  // 更新 appParams 到 webWalletData
  useDeepCompareEffect(() => {
    updateWebWalletData({ ...appParams });
  }, [appParams]);

  // 屏幕变化 设置  viewType  mobile|desktop
  useResizeObserver(isServer() ? null : document.body, (e) => {
    let viewType: ViewType = 'mobile';
    if (document.body.clientWidth >= 600) {
      viewType = 'desktop';
    }
    updateWebWalletData({ viewType });
  });

  // update particleWalletService
  useDeepCompareEffect(() => {
    updateWebWalletData({
      particleWallet: particleWalletService,
    });
  }, [particleWalletService]);

  // 登录完成后，更新 数据
  useDeepCompareEffect(() => {
    (async () => {
      if (account && particleProvider) {
        // @ts-ignore
        window.storage.setItem('_version', window.storageVersion);

        // @ts-ignore
        if (window.__connectId === 'particle' && !webWalletData.inAuthCoreIframe) {
          await switchChain(particleProvider, webWalletData.chainName, webWalletData.chainId);
        }

        const address = account;
        let chainName = webWalletData.chainName;
        let chainId = Number(webWalletData.chainId);

        if (webWalletData.inAuthCoreIframe) {
          if (chainName.toLowerCase() === 'solana' && iframeWallet.solana) {
            chainId = Number(await iframeWallet.solana.getChainId());
            chainName = chains.getSolanaChainInfoById(Number(chainId))?.name || '';
          } else if (iframeWallet.ethereum) {
            try {
              const _chainId = Number(await iframeWallet.ethereum.request({ method: 'eth_chainId' }));
              const _chainName = chains.getEVMChainInfoById(Number(_chainId))?.name || '';
              if (chainId && chainName) {
                chainId = _chainId;
                chainName = _chainName;
              }
            } catch (error) {
              //  pass
            }
          }
        }

        // @ts-ignore
        if (window.__renderChains?.length && !window.__renderChains.find((item) => item.name.toLowerCase() === chainName.toLowerCase() && Number(item.id) === Number(chainId))) {
          // @ts-ignore
          chainName = window.__renderChains[0].name;
          // @ts-ignore
          chainId = Number(window.__renderChains[0].id);
        }

        updateWebWalletData({
          address,
          trxAddress: toBase58Address(address),
          evmUnit: getParticleChainInfo(chainName, chainId).evmUnit,
          isMockAddress: !!(appParams.mockEvmAddress || appParams.mockSolanaAddress),
          displayLogoutButton: !appParams?.authUserInfo?.uuid,
        });

        updateAppParams({
          showTestNetWork: webWalletData.showTestNetWork,
          currency: webWalletData.currency,
          theme: webWalletData.theme,
          language: webWalletData?.language,
          [appParams.themeKey as string]: webWalletData.theme,
        });

        let result = null;

        if (webWalletData.inAuthCoreIframe && !sessionStorage.getItem('loginSwitch')) {
          updateWebWalletData({
            chainName,
            chainId,
          });

          updateAppParams({
            chainId,
            chainName,
          });

          if (
            // @ts-ignore
            (window.__connectId === 'particle' && !webWalletData.inAuthCoreIframe) ||
            // auth
            (webWalletData.inAuthCoreIframe && (webWalletData.supportCustomEvent === true || webWalletData.supportCustomEvent === 'auth'))
          ) {
            do {
              try {
                await switchChain(
                  (webWalletData.inAuthCoreIframe ? (webWalletData.chainName?.toLowerCase() == 'solana' ? iframeWallet.solana : iframeWallet.ethereum) : particleProvider) as any,
                  chainName,
                  chainId
                );
                result = true;
              } catch (error) {
                console.log('err', error);
                result = false;
                message.error(t('switch_chain_failed_609001e1'));
                await new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(true);
                  }, 2000);
                });
              }
            } while (!result);
          }
          sessionStorage.setItem('loginSwitch', 'true');
        }

        i18n.changeLanguage(appParams.language.replace('_', '-'));

        setTimeout(() => {
          updateWebWalletData({
            isLogin: true,
          });

          // @ts-ignore
          if (window.Sentry) {
            setTimeout(() => {
              getUserInfo().then((userInfo) => {
                const { uuid, email } = userInfo || {};
                if (uuid && email) {
                  const _userInfo = { id: uuid, username: email || '', address: account };
                  console.log('setUser', _userInfo);
                  // @ts-ignore
                  window.Sentry.setUser(_userInfo);
                }
              });
            }, 1000);
          }
        }, 200);
      }
    })();
  }, [account, particleProvider]);

  /**
   * 获取 BTC 钱包地址
   */
  useDebounceEffect(
    () => {
      if (webWalletData.address && webWalletData.isBtcConnect && webWalletData.particleWallet && webWalletData.chainInfo.chainId) {
        webWalletData.particleWallet.getBtcAddressByEvmAddress(webWalletData.address).then((btcAddress = '') => {
          updateWebWalletData({
            btcAddress,
          });
        });
      }
    },
    [webWalletData.address, webWalletData.isBtcConnect, webWalletData.particleWallet, webWalletData.chainInfo.chainId],
    {
      wait: 100,
    }
  );

  // 登录 获取缓存中的用户信息，如果没有就调用登录弹窗
  useEffect(() => {
    // @ts-ignore
    if (window.logoutFlag !== true && !appParams?.authUserInfo?.uuid && !!connectKit && !account && !connectModalOpen && !whiteList.includes(router?.pathname)) {
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(
          () => {
            reject('timeout');
          },
          window.screen.width < 600 ? 3000 : 9999999999
        );
      });

      Promise.race([cacheconnect(), timeoutPromise])
        .then((connect) => {
          if (!connect) {
            if (!account && router.pathname != '/') {
              router.replace('/');
            } else if (!account && openConnectModal && !connectModalOpen) {
              console.log(!!connect, !account, openConnectModal, !connectModalOpen);
              changLanguage(parseAppParams({}).language);
              openConnectModal();
            }
          }
        })
        .catch((error) => {
          if (error === 'timeout') {
            logout();
            return;
          }
          console.log('err', error);
          message.error(error.message);
          setTimeout(() => {
            if (!isServer()) {
              window.location.reload();
            }
          }, 500);
        });
    }
  }, [connectKit?.particle, router?.pathname, account, connectModalOpen, appParams?.authUserInfo?.uuid]);

  // 更新链
  useEffect(() => {
    let { chainName, chainId } = appParams;
    if (webWalletData.loginType === 'connect' && renderChains?.length) {
      chainName = chainName || defaultChainName;
      chainId = Number(chainId || defaultChainId);

      if (!renderChains.find((item) => item.name.toLowerCase() === defaultChainName.toLowerCase() && Number(item.id) === Number(defaultChainId))) {
        // 如果 默认链 不在 renderChains 里面，就取 renderChains 的第一条
        chainName = renderChains[0].name;
        chainId = Number(renderChains[0].id);
      }

      updateWebWalletData({
        chainName,
        chainId,
      });
    } else if (webWalletData.loginType === 'session') {
      updateWebWalletData({
        chainName,
        chainId,
      });
    }
  }, [renderChains, webWalletData.loginType]);

  /**
   * 显示 Brave shield modal
   */
  useEffect(() => {
    if (webWalletData.showBraveShieldModal) {
      showBraveShieldModal();
    }
  }, [webWalletData.showBraveShieldModal]);

  useEffect(() => {
    let isAA = false;
    if (
      webWalletData.isLogin &&
      webWalletData.chainName &&
      webWalletData.chainId &&
      webWalletData.address &&
      webWalletData.accountAbstraction &&
      webWalletData.particleWallet?.enableAccountAbstraction?.()
    ) {
      isAA = true;
    }
    updateWebWalletData({
      isAA,
    });
  }, [webWalletData.isLogin, webWalletData.chainName, webWalletData.chainId, webWalletData.address, webWalletData.accountAbstraction, webWalletData.particleWallet?.enableAccountAbstraction]);

  useEffect(() => {
    console.log('setERC4337', webWalletData.accountAbstraction);
    window?.particle?.setERC4337((webWalletData.accountAbstraction as any) ?? false);
  }, [webWalletData.accountAbstraction]);

  // 更新 chainInfo
  useDeepCompareEffect(() => {
    if (webWalletData.isLogin) {
      if (webWalletData.chainId && (webWalletData.swtichNetwork == SwtichNetworkStatus.init || webWalletData.swtichNetwork == SwtichNetworkStatus.success)) {
        const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);

        if (webWalletData.inAuthCoreIframe && window.__webWalletData.erc4337) {
          const { aaName, name, version } = window.__webWalletData.erc4337;
          const accountAbstractionChainIds = webWalletData.particleWallet.accountAbstractionChains(aaName || name, version);
          // 判断当前链是否支持aa,如果不支持先切换到支持的链
          if (!accountAbstractionChainIds.includes(Number(webWalletData.chainId))) {
            const _chainInfo = getChainList(false, '', '')?.find?.((item: any) => accountAbstractionChainIds.includes(Number(item.chainId)));
            if (_chainInfo) {
              updateWebWalletData({
                chainId: _chainInfo.chainId,
                chainName: _chainInfo.chainName,
                swtichNetwork: SwtichNetworkStatus.loading,
              });
              return;
            }
          }
          authCoreSwitchChain(Number(chainInfo.chainId));
        }

        updateWebWalletData({ chainInfo });
      }

      const swtichNetworkHistory = [...webWalletData.swtichNetworkHistory];

      swtichNetworkHistory.push({
        chainId: webWalletData.chainId,
        chainName: webWalletData.chainName,
        swtichNetwork: webWalletData.swtichNetwork,
        time: Date.now(),
      });

      updateWebWalletData({
        swtichNetworkHistory,
      });

      updateAppParams({
        chainId: webWalletData.chainId,
        chainName: webWalletData.chainName,
      });
    }
  }, [webWalletData.chainId, webWalletData.chainName, webWalletData.swtichNetwork, webWalletData.isLogin]);

  useEffect(() => {
    if (webWalletData.inAuthCoreIframe && particleProvider && webWalletData.chainName.toLowerCase() != 'solana') {
      particleProvider.on('chainChanged', (chainId: unknown) => {
        chainId = Number(chainId);
        if (chainId && chainId !== Number(webWalletData.chainId) && window.__webWalletData.chainName?.toLowerCase() !== 'solana') {
          authCoreSwitchChain(chainId as number);
        }
      });
    }
  }, [particleProvider, webWalletData.inAuthCoreIframe, webWalletData.chainId, webWalletData.chainName]);

  // swtichNetwork  切链
  useDeepCompareEffect(() => {
    if (webWalletData.swtichNetwork == SwtichNetworkStatus.loading) {
      updateWebWalletData({ balance: -1, useRequestCache: false });

      if (webWalletData.inAuthCoreIframe && (Number(webWalletData.chainId) === Solana.id || Number(webWalletData.chainId) === SolanaDevnet.id || Number(webWalletData.chainId) === SolanaTestnet.id)) {
        // authcore solana  切链
        iframeWallet.solana
          ?.switchChain(Number(webWalletData.chainId))
          .then(() => {
            const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);
            updateWebWalletData({
              chainInfo,
            });
            updateWebWalletData({ swtichNetwork: SwtichNetworkStatus.success });
          })
          .catch((error: any) => {
            console.log('err', error);
            updateWebWalletData({
              swtichNetwork: SwtichNetworkStatus.fail,
            });
            message.error(error.message);
            // @ts-ignore
            if (error.code === 4200 && webWalletData.chainId !== 101) {
              // solana 不支持切链
              updateWebWalletData({
                chainId: 101,
              });
            }
          });
      } else if (appParams?.authUserInfo?.uuid || webWalletData.inAuthCoreIframe) {
        const switchChain = webWalletData.inAuthCoreIframe
          ? walletSwitchChain(iframeWallet.ethereum, Number(webWalletData.chainId))
          : // @ts-ignore
            window.particle.auth.switchChain(
              {
                id: Number(webWalletData.chainId),
                name: webWalletData.chainName,
              } as any,
              true
            );
        switchChain
          ?.then(() => {
            const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);
            updateWebWalletData({
              chainInfo,
            });
            updateWebWalletData({ swtichNetwork: SwtichNetworkStatus.success });

            // auth core iframeWallet ethereum 切链
            if (webWalletData.inAuthCoreIframe) {
              (async () => {
                const providerChainId = (await iframeWallet.ethereum?.request({ method: 'eth_chainId' })) as string;
                if (Number(providerChainId) !== Number(webWalletData.chainId)) {
                  iframeWallet.ethereum
                    ?.request({
                      method: 'wallet_switchEthereumChain',
                      params: [{ chainId: webWalletData.chainId }],
                    })
                    .then((res) => {
                      return res;
                    });
                }
              })();
            }
          })
          .catch((error: any) => {
            console.log('err', error);
            updateWebWalletData({
              swtichNetwork: SwtichNetworkStatus.fail,
            });
            message.error(error.message);
          });
      } else if (connectId === 'particle') {
        switchChain(particleProvider as ParticleProvider, webWalletData.chainName, webWalletData.chainId)
          .then((res) => {
            const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);
            updateWebWalletData({
              chainInfo,
            });
            updateWebWalletData({ swtichNetwork: SwtichNetworkStatus.success });
          })
          .catch((error) => {
            updateWebWalletData({
              swtichNetwork: SwtichNetworkStatus.fail,
            });
            console.log('err', error);
            message.error(error.message);
          });
      } else {
        const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);
        updateWebWalletData({
          chainInfo,
          swtichNetwork: SwtichNetworkStatus.success,
        });
        switchChain(particleProvider as ParticleProvider, webWalletData.chainName, webWalletData.chainId);
      }
    }
  }, [webWalletData.swtichNetwork, particleProvider, connectId, webWalletData.inAuthCoreIframe]);

  // 切换语言
  useDeepCompareEffect(() => {
    if (webWalletData?.language) {
      changLanguage(webWalletData?.language);
      i18n.changeLanguage(webWalletData?.language);
      window.storage.setItem('language', webWalletData?.language);

      updateAppParams({
        language: webWalletData?.language,
      });
    }
  }, [webWalletData?.language]);

  // 切换主题
  useDeepCompareEffect(() => {
    if (webWalletData.theme && window.particle && webWalletData.isLogin) {
      if (appParams.themeKey) {
        window.storage.setItem(appParams.themeKey, webWalletData.theme);
      }
      let classList = [...document.body.classList];
      let index = classList.findIndex((val) => val.includes('theme-'));
      if (index > -1) {
        document.body.classList.remove(classList[index]);
        document.documentElement.classList.remove(classList[index]);
      }
      document.body.classList.add('theme-' + webWalletData.theme);
      document.documentElement.classList.add('theme-' + webWalletData.theme);
      setProviderTheme && setProviderTheme(webWalletData.theme as string);
      window.particle.setAuthTheme({
        uiMode: webWalletData.theme as UIMode,
        // @ts-ignore
        ...(window.__appParams?.authTheme || {}),
      });
      window.storage.setItem('themeMode', webWalletData.theme);

      // update app params
      updateAppParams({
        theme: webWalletData.theme,
        [appParams.themeKey as string]: webWalletData.theme,
      });
    }

    document.documentElement.style.colorScheme = webWalletData.theme || 'light';
  }, [webWalletData.theme, webWalletData.isLogin]);

  // 根据主题 更新 solana 图标的颜色
  useDebounceEffect(
    () => {
      if (webWalletData.isLogin) {
        const chainInfo = updateChainInfo(true, webWalletData.chainId.toString(), webWalletData.chainName);
        updateWebWalletData({ chainInfo });
      }
    },
    [webWalletData.theme, webWalletData.chainId, webWalletData.isLogin],
    {
      wait: 500,
    }
  );

  // initPWC
  useDeepCompareEffect(() => {
    // @ts-ignore
    if (window.logoutFlag) {
      return;
    }
    if (particleWalletService && webWalletData.chainName && appParams.evmSupportWalletConnect === true) {
      if (webWalletData.chainName.toLowerCase().includes('solana') || webWalletData.chainName.toLowerCase() === 'tron') {
        console.log('WC2Manager deinit');
        webWalletData.wc2Manager?.deinit();
        updateWebWalletData({ wc2Manager: undefined });
      } else if (particleWalletService instanceof EvmService) {
        debounceInitWC(particleProvider, particleWalletService as EvmService, updateWalletconnectData, webWalletData.events, webWalletData.accountAbstraction);
      }
    }
    updateWebWalletData({ evmSupportWalletConnect: appParams.evmSupportWalletConnect });
  }, [particleWalletService, webWalletData.chainName, particleProvider, appParams.evmSupportWalletConnect]);

  //evmProvider: any, particleWallet: EvmService, updateWalletconnectData: any, accountAbstraction?: boolean
  const { run: debounceInitWC } = useDebounceFn(
    (evmProvider, particleWallet, updateWalletconnectData, events, accountAbstraction) => {
      initWC2Manager(evmProvider, particleWallet, updateWalletconnectData, events, accountAbstraction)
        .then((wc2Manager) => updateWebWalletData({ wc2Manager }))
        .catch((error) => {
          console.log('init WC Manager error', error);
        });
    },
    {
      wait: 500,
    }
  );

  // 是否显示扫一扫入口
  useEffect(() => {
    if (webWalletData.isLogin && webWalletData.chainName.toLowerCase() !== 'tron') {
      updateWebWalletData({ displayScan: true });
    } else {
      updateWebWalletData({ displayScan: false });
    }
  }, [webWalletData.isLogin, webWalletData.chainName]);

  useDeepCompareEffect(() => {
    updateWebWalletData({ t });
  }, [t]);

  // mock address 标识
  useEffect(() => {
    const id = 'mock-address';
    const el = document.getElementById(id);
    if (webWalletData.isMockAddress && !el) {
      const mockAddress = document.createElement('div');
      mockAddress.id = id;
      document.body.appendChild(mockAddress);
    } else if (!webWalletData.isMockAddress && el) {
      el.remove();
    }
  }, [webWalletData.isMockAddress]);

  // 切换 currency  更新 currency
  useEffect(() => {
    if (webWalletData.currency) {
      updateAppParams({
        currency: webWalletData.currency,
      });

      // @ts-ignore
      window?.particle?.setFiatCoin?.(webWalletData.currency as CurrencyUnit);
    }
  }, [webWalletData.currency]);

  useEffect(() => {
    if (selectedGlobalChain && webWalletData.currency !== 'USD') {
      updateWebWalletData({
        currency: 'USD',
      });
      webWalletData.particleWallet.setCurrency('USD');
    }
  }, [selectedGlobalChain, webWalletData.currency]);

  useEffect(() => {
    updateAppParams({
      showTestNetWork: webWalletData.showTestNetWork,
    });
  }, [webWalletData.showTestNetWork]);

  /**
   * 如果 chainId 不是主网链，显示测试网标识
   */
  useEffect(() => {
    const chain = chains.getEVMChainInfoById(Number(webWalletData.chainId || 1));
    if (chain && chain.network !== 'Mainnet') {
      console.log('showTestNetWork', true);

      setTimeout(() => {
        updateWebWalletData({
          showTestNetWork: true,
        });
      });
    }
  }, [webWalletData.chainId]);

  /**
   * 如果切链失败，重置 chainId，从 webWalletData.swtichNetworkHistory 里面找最新一条
   */
  useEffect(() => {
    if (webWalletData?.swtichNetwork === SwtichNetworkStatus.fail) {
      setTimeout(() => {
        const swtichNetworkHistory = [...webWalletData.swtichNetworkHistory];
        const { chainId = appParams.chainId, chainName = appParams.chainName } =
          swtichNetworkHistory.reverse().find((item) => item.swtichNetwork == SwtichNetworkStatus.success || item.swtichNetwork == SwtichNetworkStatus.init) || {};

        updateWebWalletData({
          chainId,
          chainName,
          particleWallet: getParticleWalletService(webWalletData.walletProvider, {
            chainName: chainName,
            chainId: chainId,
            appId: webWalletData.appId,
            clientKey: webWalletData.clientKey,
            projectId: webWalletData.projectId,
            currency: webWalletData.currency,
            accountAbstraction: webWalletData.accountAbstraction,
            events: webWalletData.events,
          }),
          swtichNetwork: SwtichNetworkStatus.loading,
        });
      }, 300);
    }
  }, [webWalletData?.swtichNetwork, webWalletData.swtichNetworkHistory, webWalletData.walletProvider]);

  useEffect(() => {
    updateAppParams({
      hideSuspiciousTokens: webWalletData.hideSuspiciousTokens,
      hideSuspiciousNfts: webWalletData.hideSuspiciousNfts,
    });
  }, [webWalletData.hideSuspiciousTokens, webWalletData.hideSuspiciousNfts]);

  // useEffect(() => {
  //   if (webWalletData.address && webWalletData.loginType && webWalletData.loginType !== 'session' && window.storage.getItem('sessionAddress')) {
  //     window.storage.clear();
  //     window.storage.clear();
  //     location.replace('/');
  //   } else if (webWalletData.address && webWalletData.loginType && webWalletData.loginType == 'session') {
  //     window.storage.setItem('sessionAddress', webWalletData.address);
  //   }
  // }, [webWalletData.address, webWalletData.loginType]);

  // 更新 mHistory
  useEffect(() => {
    if (typeof window.mHistory === 'undefined') {
      window.mHistory = [];
    }
    const mHistory = window.mHistory;
    const lastRouter = mHistory.length ? mHistory[mHistory.length - 1] : null;
    if (!lastRouter || (lastRouter && lastRouter.pathname !== router.pathname)) {
      mHistory.push({
        ...router,
      });
    }
    // 清除缓存 TokenDetail
    if (
      router.pathname &&
      webWalletData.isLogin &&
      mHistory.length > 1 &&
      mHistory[mHistory.length - 2].pathname == '/tokenDetail' &&
      mHistory[mHistory.length - 1].pathname !== '/transactionDetail' &&
      mHistory[mHistory.length - 2].pathname == '/uaTransactionDetail'
    ) {
      webWalletData.clearPageCache(PageType.TokenDetail);
    }
  }, [router.pathname, webWalletData.isLogin]);

  /**
   * 在组件挂载或者 webWalletData.address 发生变化时，更新 webWalletData 中的 omitAddress 和 copyAddress 字段。
   *
   * @param {Object} webWalletData - Web 钱包数据对象
   * @param {string} webWalletData.address - 钱包地址
   * @param {Function} updateWebWalletData - 更新 Web 钱包数据的函数
   */
  useEffect(() => {
    if (webWalletData.address) {
      const address = webWalletData.address;
      const omitAddress = (address || '').substring(0, 5) + '...' + (address || '').substring((address || '').length - 5);
      const copyAddress = address;
      updateWebWalletData({
        omitAddress,
        copyAddress,
      });
    }
  }, [webWalletData.address]);

  useTrackedEffect(
    (changes) => {
      const arr = [
        'webWalletData.isLogin',
        'webWalletData.chainInfo',
        'webWalletData.userInfo',
        'webWalletData.address',
        'webWalletData.btcAddress',
        'webWalletData.particleWallet',
        'webWalletData.exchangeRate',
        'webWalletData.balance',
        'webWalletData.language',
        'webWalletData.swtichNetwork',
        'webWalletData.viewType',
        'webWalletData.supportChains',
        'webWalletData.events',
        'webWalletData.evmSupportWalletConnect',
        'webWalletData.wc2Manager',
        'webWalletData.loginType',
        'webWalletData.displayNFTContractAddresses',
        'webWalletData.topMenuType',
        'webWalletData.chainName',
        'webWalletData.chainId',
        'webWalletData.theme',
        'webWalletData.currency',
        'webWalletData.accountAbstraction',
        'webWalletData.displayTokenAddresses',
        'webWalletData.priorityTokenAddresses',
        'webWalletData.priorityNFTContractAddresses',
        'webWalletData.showBraveShieldModal',
        'webWalletData.showTestNetWork',
        'webWalletData.displayAccountAndSecurityMenu',
        'webWalletData.isBtcConnect',
        'webWalletData.isUniversal',
        'webWalletData.logout',
        'webWalletData.isAA',
        'webWalletData.pageLoading',
        'webWalletData.supportBridgeChains',
        'webWalletData.inAuthCoreIframe',
        'webWalletData.getWalletClient',
        'webWalletData.getPublicClient',
        'webWalletData.walletSwitchChain',
        'webWalletData.toBytes',
        'webWalletData.supportCustomEvent',
        'webWalletData.walletProvider',
        'webWalletData.publicProvider',
      ];
      // console.log('>>>> e1 Index of changed dependencies: ', changes, changes?.map((index) => arr[index].replace('webWalletData.', ''))?.join(', '), Math.random());
    },
    [
      webWalletData.isLogin,
      webWalletData.chainInfo,
      webWalletData.userInfo,
      webWalletData.address,
      webWalletData.btcAddress,
      webWalletData.particleWallet,
      webWalletData.exchangeRate,
      webWalletData.balance,
      webWalletData.language,
      webWalletData.swtichNetwork,
      webWalletData.viewType,
      webWalletData.supportChains,
      webWalletData.events,
      webWalletData.evmSupportWalletConnect,
      webWalletData.wc2Manager,
      webWalletData.loginType,
      webWalletData.displayNFTContractAddresses,
      webWalletData.topMenuType,
      webWalletData.chainName,
      webWalletData.chainId,
      webWalletData.theme,
      webWalletData.currency,
      webWalletData.accountAbstraction,
      webWalletData.displayTokenAddresses,
      webWalletData.priorityTokenAddresses,
      webWalletData.priorityNFTContractAddresses,
      webWalletData.showBraveShieldModal,
      webWalletData.showTestNetWork,
      webWalletData.displayAccountAndSecurityMenu,
      webWalletData.isBtcConnect,
      webWalletData.isUniversal,
      webWalletData.logout,
      webWalletData.isAA,
      webWalletData.pageLoading,
      webWalletData.supportBridgeChains,
      webWalletData.inAuthCoreIframe,
      webWalletData.getWalletClient,
      webWalletData.getPublicClient,
      webWalletData.walletSwitchChain,
      webWalletData.toBytes,
      webWalletData.supportCustomEvent,
      webWalletData.walletProvider,
      webWalletData.publicProvider,
    ]
  );

  return (
    <WalletContent.Provider
      value={{ webWalletData, updateWebWalletData, updatePageData, walletconnectData, updateWalletconnectData, t, didToAddresses, setDidToAddresses, btcToAddresses, setBtcToAddresses }}
    >
      {appLoading ? <Loading spinning={appLoading} copyright={true} position="appLoading" /> : props.children}
      {webWalletData?.pageLoading?.show && <Loading spinning={webWalletData?.pageLoading?.show} displayMask={webWalletData?.pageLoading.displayMask ?? true} />}
    </WalletContent.Provider>
  );
};
