import { useWebWallet } from '@/context/WebWalletContext';
import { maxZIndex } from '@/utils/index';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ApprovePopup = () => {
  const { webWalletData } = useWebWallet();
  const [containerClassList, setContainerClassList] = useState(['wallet-particle-approve-popup']);
  const [zIndex, setZIndex] = useState<number>(100);
  const { t } = useTranslation();

  useEffect(() => {
    if (webWalletData?.isLogin && webWalletData?.events) {
      webWalletData.events.on('event:switchChain:approve', () => {
        setZIndex(maxZIndex() + 1);
        setContainerClassList(['wallet-particle-approve-popup', 'wallet-particle-approve-popup-display']);
        setTimeout(() => {
          setContainerClassList(['wallet-particle-approve-popup', 'wallet-particle-approve-popup-display', 'wallet-particle-approve-popup-show']);
        }, 50);
      });
      webWalletData.events.on('event:switchChain:success', () => {
        // pass
      });
    }
  }, [webWalletData?.isLogin, webWalletData?.events]);

  return (
    <div
      className={containerClassList.join(' ')}
      style={{
        zIndex,
      }}
    >
      <div className="wallet-particle-pam-left">
        <div className="wallet-particle-pam-wrap">
          <div className="wallet-particle-pam-img">
            <img src="/images/wallet_icon.svg" alt="" />
          </div>
          <div className="wallet-particle-pam-content">
            <div className="wallet-particle-pam-title">{t('Approve_popup')}</div>
            <div className="wallet-particle-pam-text">{t('click_continue')}</div>
          </div>
        </div>
      </div>
      <div
        className="wallet-particle-pam-right ori-parm-continue-btn"
        onClick={() => {
          webWalletData.events.emit('event:switchChain:execute');
          setContainerClassList(['wallet-particle-approve-popup']);
        }}
      >
        {t('continue')}
      </div>
    </div>
  );
};

export default memo(ApprovePopup);
