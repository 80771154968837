import ApprovePopup from '@/components/approvePopup';
import CustomStyle from '@/components/customStyle';
import Erc4337GasModal from '@/components/erc4337GasModal';
import SessionRequest from '@/components/sessionRequest';
import { defaultStyle } from '@/components/style';
import SwitchChain from '@/components/switchChain';
import { RouterHistoryProvider } from '@/context/RouterHistoryContext';
import { UniversalAccountProvider } from '@/context/UniversalAccountContext';
import { WebWalletContextProvider } from '@/context/WebWalletContext';
import useAppParams from '@/hooks/useAppParams';
import useDevice from '@/hooks/useDevice';
import '@/styles/globals.less';
import '@/styles/styles.less';
import { getCacheData, isMobile, isServer, isSmallScreen, setCacheData, setProperty, setViewHeight } from '@/utils/index';
import { ParticleChains } from '@particle-network/chains';
import { ModalProvider } from '@particle-network/connect-react-ui';
import '@particle-network/connect-react-ui/dist/index.css';
import { useDebounceEffect, useSize } from 'ahooks';
import { ConfigProvider, message } from 'antd';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { KeepAliveProvider } from 'react-next-keep-alive';
import { bootstrapContent } from 'src/bootstrap';
import i18n from 'src/i18n';
console.log('language', i18n.language);

const DynamicComponents = dynamic(import('@/components/dynamic'), { ssr: false });

type NextPageWithLayout = NextPage;

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

// 白名单页面
const whiteList = ['/iconDemo'];

if (!isServer()) {
  // @ts-ignore
  window.__PARTICLE_ENVIRONMENT__ = process.env.NEXT_PUBLIC_APP_ENV;

  // if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
  //   // @ts-ignore
  //   window.__PARTICLE_AUTH_LOCALHOST__ = 'http://localhost:3077';
  // }

  // @ts-ignore
  window.__ParticleChains = ParticleChains;
  // @ts-ignore
  window.__i18n = i18n;

  // @ts-ignore
  window.__getCacheData = getCacheData;
  // @ts-ignore
  window.__setCacheData = setCacheData;

  // @ts-ignore
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
  });

  window.cacheData = {};
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  const [pageClass, setPageClass] = useState('');

  const { appParams, updateAppParams, modalProviderParams } = useAppParams({});

  const device = useDevice();

  useEffect(() => {
    const pageName = router.pathname.replace(/^\//, '').replace(/\//g, '-') || 'index';
    setPageClass(`page-${pageName}`);

    // body add class
    const classList: any = document.body.classList;
    const index = [...classList.values()].findIndex((val: string) => /^_page_/.test(val));
    const className = `_page_${pageName}`;
    if (index > -1) {
      classList.remove(classList[index]);
    }
    classList.add(className);
  }, [router]);

  const bodySize = useSize(() => document.querySelector('body'));

  useDebounceEffect(
    () => {
      if (isSmallScreen()) {
        document.body.classList.add('mobile-page');
        document.body.classList.remove('pc-page');
      } else {
        document.body.classList.remove('mobile-page');
        document.body.classList.add('pc-page');
      }
      const _isMobile = isMobile();
      document.documentElement.setAttribute('data-mobile', _isMobile ? 'true' : 'false');
    },
    [bodySize],
    {
      wait: 100,
    }
  );

  useEffect(() => {
    if (device) {
      document.body.setAttribute('data-device', device);
    } else {
      document.body.removeAttribute('data-device');
    }
  }, [device]);

  useEffect(() => {
    if (!isServer()) {
      setViewHeight();
      if (location.hostname.includes('particle.network') && !/debug=true/.test(location.search)) {
        import('@/utils/sentry')
          .then(({ sentryInit }) => {
            if (sentryInit) {
              // @ts-ignore
              window.Sentry = sentryInit();
            }
          })
          .catch((err) => {
            console.log('sentryInit err', err);
          });

        import('@/utils/firebase')
          .then(({ firebaseInit }) => {
            firebaseInit && firebaseInit();
          })
          .catch((err) => {
            console.log('firebaseInit err', err);
          });
      }

      const domain = 'particle.network';
      if (location.host.includes(domain)) {
        document.domain = domain;
      }

      // set window.screen.height
      let screenHeight = window.screen.height;
      setProperty('--screenHeight', screenHeight + 'px');

      // @ts-ignore
      // window.__setCacheData = setCacheData;
      // @ts-ignore
      // window.__getCacheData = getCacheData;
    }

    //@ts-ignore
    // if (window.isCookieEnabled()) {
    //   Modal.warning({
    //     className: 'system-warning-confirm',
    //     content: <>Please allow Cookie in your browser settings.</>,
    //   });
    // }

    // 路由变化 清除弹窗
    // const handleRouteChange = () => {
    //   console.log(111);

    //   Modal.destroyAll();
    // };

    // router.events.on('routeChangeComplete', handleRouteChange);
    // return () => {
    //   router.events.off('routeChangeComplete', handleRouteChange);
    // };
    message.config({
      duration: 1.5,
    });
  }, []);

  // delete the old database
  if (!isServer()) {
    setTimeout(() => {
      try {
        ['web-wallet', 'web-wallet-1'].forEach((name) => {
          indexedDB.deleteDatabase(name);
        });
        ['supportChains', 'supportUIModeSwitch', 'displayNFTContractAddresses', 'priorityNFTContractAddresses', 'priorityTokenAddresses', 'displayTokenAddresses', 'supportAddToken'].map((name) => {
          localStorage.removeItem(name);
        });
      } catch (error) {
        console.log('deleteDatabase error', error);
      }
    }, 1000);
  }

  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <Head>
        <title>Particle Wallet</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no,viewport-fit=cover"></meta>
        <meta property="description" key="description" content="particle wallet" />
        <style data-cfasync="false" dangerouslySetInnerHTML={{ __html: defaultStyle }} />
        <script data-cfasync="false" dangerouslySetInnerHTML={{ __html: bootstrapContent }} />
      </Head>
      {whiteList.includes(router.route) ? (
        <div
          style={{
            height: bodySize?.height ? bodySize.height : '100vh',
          }}
        >
          <DynamicComponents>
            <div className="default-layout">
              <div className="layout-main">
                {/* @ts-ignore */}
                <Component {...pageProps} />
              </div>
              <div className={'default-layout-footer ' + pageClass}>
                <div className="logo"></div>
              </div>
            </div>
          </DynamicComponents>
        </div>
      ) : (
        <ModalProvider {...modalProviderParams}>
          <div
            style={{
              height: bodySize?.height ? bodySize.height : '100vh',
            }}
          >
            <RouterHistoryProvider>
              <UniversalAccountProvider appParams={appParams}>
                <WebWalletContextProvider appParams={appParams} updateAppParams={updateAppParams} i18n={i18n}>
                  <DynamicComponents>
                    <div className="default-layout">
                      <div className="layout-main">
                        <KeepAliveProvider router={router}>
                          {/* @ts-ignore */}
                          <Component {...pageProps} appParams={appParams} />
                        </KeepAliveProvider>
                        <SessionRequest />
                        <SwitchChain />
                        <ApprovePopup />
                        <Erc4337GasModal />
                        {!!appParams.customStyleSetting && <CustomStyle />}
                      </div>
                      <div className={'default-layout-footer ' + pageClass}>
                        <div className="logo"></div>
                      </div>
                    </div>
                  </DynamicComponents>
                </WebWalletContextProvider>
              </UniversalAccountProvider>
            </RouterHistoryProvider>
          </div>
        </ModalProvider>
      )}
    </ConfigProvider>
  );
}

export default MyApp;
