import { useWebWallet } from '@/context/WebWalletContext';
import { Drawer, Modal } from 'antd';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import MIcon from '../mIcon';

interface IProps {
  children?: React.ReactNode;
  visible?: boolean;
  containerSelector?: any;
  title?: string | any;
  modalProps?: any;
  locked?: 'modal' | 'drawer';
  drawerProps?: any;
  className?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

const WModel = forwardRef((props: IProps, ref) => {
  const { webWalletData } = useWebWallet();
  const { children, title, drawerProps = {}, modalProps = {} } = props;

  const [visible, setVisible] = useState(props.visible || false);

  const onClose = () => {
    setVisible(false);
    props.onClose && props.onClose();
  };

  const onOpen = () => {
    setVisible(true);
    props.onOpen && props.onOpen();
  };

  useImperativeHandle(ref, () => ({
    setVisible,
    onOpen,
    onClose,
  }));

  useEffect(() => {
    setVisible(props.visible ?? false);
  }, [props.visible]);

  if (modalProps?.style?.top) {
    modalProps.style.top = '';
  }

  return (
    <>
      {webWalletData.viewType == 'desktop' || props.locked === 'modal' ? (
        <Modal
          title={title}
          open={visible}
          okText=""
          wrapClassName={`w-model-container ${props.className || ''}`}
          centered={true}
          maskClosable={false}
          getContainer={() => {
            return props.containerSelector ? document.querySelector(props.containerSelector) : document.body;
          }}
          onCancel={() => {
            onClose();
          }}
          closeIcon={<MIcon name="icon_63" size={30} />}
          {...modalProps}
          styles={{
            ...modalProps.styles,
          }}
        >
          {children}
        </Modal>
      ) : (
        <Drawer
          className={`w-drawer-container ${props.className || ''}`}
          closable={true}
          onClose={onClose}
          open={visible}
          placement="bottom"
          getContainer={() => {
            return props.containerSelector ? document.querySelector(props.containerSelector) : document.body;
          }}
          extra={
            <>
              <MIcon name="icon_63" size={30} onClick={() => onClose()} />
            </>
          }
          title={title}
          {...drawerProps}
        >
          {children}
        </Drawer>
      )}
    </>
  );
});

export default WModel;
