import { useWebWallet } from '@/context/WebWalletContext';
import { getCloudflareTurnstileResponse } from '@/utils/index';
import { DecimalUnitMap, EVM_DEFAULT_ADDRESS, EVM_SWAP_NATIVE_ADDDRESS, FeeQuote, TransactionType, fromWei, particleChain, particleChainV2, tokenAmountFormat } from '@particle-network/wallet-core';
import { Button, Image } from 'antd';
import BigNumber from 'bignumber.js';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WModel from '../WModel';
import MIcon from '../mIcon';

enum SendTransactionMode {
  UserSelect = 0,
  Gasless = 1,
  UserPaidNative = 2,
}

const Erc4337GasModal = () => {
  const [open, setOpen] = useState(false);
  const [feeQuotes, setFeeQuotes] = useState<FeeQuote[]>();
  const [selectFeeQuote, setSelectFeeQuote] = useState<FeeQuote>();
  const { webWalletData } = useWebWallet();
  const [sendTransactionData, setSendTransactionData] = useState<any[]>([]);
  const { t } = useTranslation();
  const [sendLoading, setSendLoading] = useState(false);

  const isUniversalAccount = useMemo(() => {
    return (webWalletData.accountAbstraction as any)?.name === 'UNIVERSAL';
  }, [webWalletData.accountAbstraction]);

  useEffect(() => {
    console.log('isUniversalAccount ----', isUniversalAccount);
  }, [isUniversalAccount]);

  useEffect(() => {
    webWalletData.events.on('erc4337:prepareTransaction', (feeQuotesResponse: any) => {
      let feeQuotes: FeeQuote[] = [];

      // gasless
      feeQuotes.push({
        address: '',
        symbol: t('gasless'),
        decimal: 0,
        logoUrl: require('@/images/gasless.svg'),
        tokenGasPrice: 0,
        offset: 0,
        payment: '',
        refundReceiver: '',
        tokenBalance: '',
        transactionMode: SendTransactionMode.Gasless,
        userOp: feeQuotesResponse?.verifyingPaymasterGasless?.userOp,
        userOpHash: feeQuotesResponse?.verifyingPaymasterGasless?.userOpHash,
      } as unknown as FeeQuote);

      // native
      if (feeQuotesResponse.verifyingPaymasterNative) {
        feeQuotes.push({
          transactionMode: SendTransactionMode.UserPaidNative,
          ...feeQuotesResponse.verifyingPaymasterNative.feeQuote,
          ...feeQuotesResponse.verifyingPaymasterNative.feeQuote?.tokenInfo,
          userOp: feeQuotesResponse?.verifyingPaymasterNative?.userOp,
          userOpHash: feeQuotesResponse?.verifyingPaymasterNative?.userOpHash,
        } as unknown as FeeQuote);
      }

      // token
      if (feeQuotesResponse.tokenPaymaster) {
        const { tokenPaymasterAddress } = feeQuotesResponse.tokenPaymaster;

        feeQuotes.push(
          ...feeQuotesResponse.tokenPaymaster.feeQuotes.map((item: any) => {
            const feeQuote = cloneDeep(item);
            return {
              transactionMode: SendTransactionMode.UserSelect,
              ...item,
              ...item.tokenInfo,
              _feeQuote: feeQuote,
              tokenPaymasterAddress,
            } as unknown as FeeQuote;
          })
        );
      }

      // universal
      if (feeQuotesResponse.universalPaymaster) {
        feeQuotes.unshift({
          ...feeQuotesResponse.universalPaymaster,
          ...particleChain,
          ...particleChain?.nativeCurrency,
          logoUrl: particleChain?.nativeIcon,
          smartAccountContractName: feeQuotesResponse?.universalPaymaster?.smartAccountContractName,
          usdgUserOps: feeQuotesResponse?.universalPaymaster?.usdgUserOps,
        });
      }

      setSendTransactionData(feeQuotesResponse.sendTransactionData || []);

      setFeeQuotes(
        feeQuotes.map((item: any) => {
          item.logoUrl = item.logoUrl || item.logoURI;
          item.tokenBalance = item.tokenBalance || item.balance;
          item.payment = item.payment || item.fee;
          item.decimal = item.decimal || item.decimals;
          return item;
        })
      );

      setOpen(true);
    });
    return () => {
      webWalletData.events.removeAllListeners('erc4337:prepareTransaction');
    };
  }, []);

  useEffect(() => {
    if (feeQuotes) {
      for (let feeQuote of feeQuotes) {
        if (hasEnoughGas(feeQuote)) {
          setSelectFeeQuote(feeQuote);
          break;
        }
      }
    }
  }, [feeQuotes]);

  useEffect(() => {
    if (!open) {
      setFeeQuotes(undefined);
      setSelectFeeQuote(undefined);
    }
  }, [open]);

  const onClose = () => {
    webWalletData.events.emit('erc4337:sendTransactionError', new Error('Transaction cancelled'));
    setOpen(false);
  };

  const sendTransaction = (token?: string) => {
    // // 如果非gasless发送，需要检查余额是否足够,检查发送的token跟选择gas的token是否一致，如果一致需要检查余额是否足够，taoen余额 - 发送金额 < gas

    // /**
    //  * 选择的token地址
    //  */
    // const selectTokenAddress = selectFeeQuote?.address?.toLowerCase() === EVM_DEFAULT_ADDRESS ? 'native' : selectFeeQuote?.address?.toLowerCase();

    // if (
    //   // 非 gasless
    //   selectFeeQuote?.transactionMode !== SendTransactionMode.Gasless &&
    //   // 发送的token跟选择gas的token是否一致
    //   selectTokenAddress === sendTransactionData?.from?.toLowerCase() &&
    //   // 检查余额不足 （余额-发送金额<gas）
    //   // 当前余额
    //   new BigNumber(selectFeeQuote?.tokenBalance || 0)
    //     // 减去发送金额
    //     .minus(new BigNumber(sendTransactionData.amount).multipliedBy(10 ** (selectFeeQuote?.decimal || 0)))
    //     // 小于 selectFeeQuote.payment
    //     .lt(new BigNumber(selectFeeQuote?.payment || 0))
    // ) {
    //   message.error(webWalletData.t('insufficient_balance'));
    //   return;
    // }
    webWalletData.events.emit('erc4337:sendTransaction', {
      // @ts-ignore
      feeQuote: selectFeeQuote?._feeQuote || selectFeeQuote,
      userOp: selectFeeQuote?.userOp,
      transactionMode: selectFeeQuote?.transactionMode,
      userOpHash: selectFeeQuote?.userOpHash,
      tokenPaymasterAddress: selectFeeQuote?.tokenPaymasterAddress,
      // @ts-ignore
      smartAccountContractName: selectFeeQuote?.smartAccountContractName,
      // @ts-ignore
      usdgUserOps: selectFeeQuote?.usdgUserOps,
      // @ts-ignore
      smartAccount: selectFeeQuote?.smartAccount,
      // @ts-ignore
      txs: selectFeeQuote?.txs,
      cfTurnstileResponse: token,
    });
    setOpen(false);
  };

  const feeTokenBalance = (feeQuote: FeeQuote) => {
    //@ts-ignore
    const unit = DecimalUnitMap[feeQuote.decimal];
    return `${fromWei(feeQuote.tokenBalance, unit)}`;
  };

  const formatFeeQuote = (feeQuote: FeeQuote) => {
    // @ts-ignore
    const unit = DecimalUnitMap[feeQuote.decimal];
    console.log('formatFeeQuote', feeQuote.payment, feeQuote.decimal, unit);

    try {
      return `-${tokenAmountFormat(new BigNumber(feeQuote.payment).dividedBy(new BigNumber(10).pow(feeQuote.decimal)), 9, 0, 10)}`;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

  /**
   * 判断是否有足够的代币余额来支付手续费。
   * @param feeQuote - 包含手续费和代币余额信息的对象。
   * @returns {boolean} - 如果代币余额足够支付手续费，则返回true，否则返回false。
   */
  const hasEnoughGas = (feeQuote: FeeQuote): boolean => {
    if (feeQuote.transactionMode === SendTransactionMode.Gasless) {
      return !!feeQuote?.userOp;
    } else {
      // 非gasless

      const fee = feeQuote.payment;
      const balance = feeQuote.tokenBalance;

      // 发送的token地址
      const sendTokenAddress = sendTransactionData[0]?.from === EVM_DEFAULT_ADDRESS || sendTransactionData[0]?.from === EVM_SWAP_NATIVE_ADDDRESS ? 'native' : sendTransactionData[0]?.from;

      const nativeToken = sendTransactionData.find(
        (item) => item.from?.toLowerCase() == 'native' || item.from?.toLowerCase() == EVM_DEFAULT_ADDRESS || item.from?.toLowerCase() == EVM_SWAP_NATIVE_ADDDRESS
      );

      if (feeQuote?.transactionType === 'nft') {
        return new BigNumber(balance).gte(new BigNumber(fee));
      } else if (
        TransactionType.BATCH_TRANSFER === sendTransactionData[0]?.type &&
        (feeQuote.address?.toLowerCase() === EVM_DEFAULT_ADDRESS || feeQuote.address?.toLowerCase() === EVM_SWAP_NATIVE_ADDDRESS) &&
        nativeToken
      ) {
        // 批量转账: 只检查检查native
        const sendAmount = new BigNumber(nativeToken?.amount).multipliedBy(10 ** (nativeToken?.decimals || 0));
        return !!(balance && new BigNumber(balance).gte(new BigNumber(sendAmount).plus(new BigNumber(fee))));
      } else if (
        // Swap,Bridge,Transfer:  需要检查余额是否足够支付（gas和发送金额）
        TransactionType.NFT !== sendTransactionData[0]?.type &&
        TransactionType.BATCH_NFT !== sendTransactionData[0]?.type &&
        TransactionType.BATCH_TRANSFER !== sendTransactionData[0]?.type &&
        (sendTokenAddress?.toLowerCase() === feeQuote.address?.toLowerCase() ||
          (sendTokenAddress?.toLowerCase() === 'native' && (feeQuote.address?.toLowerCase() === EVM_DEFAULT_ADDRESS || feeQuote.address?.toLowerCase() === EVM_SWAP_NATIVE_ADDDRESS)))
      ) {
        // 检查发送的token跟选择gas的token是否一致，如果一致需要检查余额是否足够，taoen余额 > 发送金额 + gas
        const sendAmount = new BigNumber(sendTransactionData[0]?.amount).multipliedBy(10 ** (feeQuote?.decimal || 0));
        return !!(balance && new BigNumber(balance).gte(new BigNumber(sendAmount).plus(new BigNumber(fee))));
      } else {
        return !!(balance && new BigNumber(balance).gte(new BigNumber(fee)));
      }
    }
  };

  const contentBody = () => {
    return (
      <div className="fee-drawer-content">
        <div className="fee-list">
          {feeQuotes &&
            feeQuotes.map((feeQuote) => {
              return (
                <div
                  className={`gas-fee-item ${hasEnoughGas(feeQuote) ? '' : 'disabled'}`}
                  data-selected={selectFeeQuote?.address === feeQuote.address}
                  key={feeQuote.address + '_' + webWalletData.chainId}
                  onClick={() => {
                    if (hasEnoughGas(feeQuote)) {
                      setSelectFeeQuote(feeQuote);
                    }
                  }}
                >
                  <div className="fee-radio" data-checked={selectFeeQuote?.address === feeQuote.address} data-disabled={!hasEnoughGas(feeQuote)}>
                    {selectFeeQuote?.address === feeQuote.address ? <MIcon name="icon_57" size={15} /> : <MIcon name="icon_58" size={15} />}
                  </div>
                  <Image className="fee-token-icon" preview={false} src={feeQuote.logoUrl} key={feeQuote.logoUrl}></Image>
                  <div className="fee-token-info">
                    <div className="fee-name">{feeQuote.symbol}</div>
                    {/* @ts-ignore */}
                    {feeQuote?.smartAccountContractName === 'UNIVERSAL' ? (
                      <div className="universal-tag-wrap">
                        <div className="universal-tag">Universal Gas Token</div>
                      </div>
                    ) : (
                      <>{!!feeQuote.address && <div className="fee-address">{feeQuote.address.substring(0, 5) + '...' + feeQuote.address.substring(feeQuote.address.length - 5)}</div>}</>
                    )}
                  </div>
                  {feeQuote.transactionMode !== SendTransactionMode.Gasless ? (
                    <div className="fee-token-balance">
                      <div className="gas-fee">{formatFeeQuote(feeQuote)}</div>
                      <div className="token-balance">{hasEnoughGas(feeQuote) ? feeTokenBalance(feeQuote) : t('insufficient_balance')}</div>
                    </div>
                  ) : (
                    <div className="fee-token-balance">
                      <div className="gas-fee">{t('free')}</div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        <div className="bootom-action">
          <Button
            type="primary"
            className="btn-user-paid"
            loading={sendLoading}
            onClick={() => {
              // @ts-ignore
              if (selectFeeQuote?.id === particleChain.id || selectFeeQuote?.id === particleChainV2.id) {
                setSendLoading(true);
                getCloudflareTurnstileResponse({
                  theme: 'light',
                  language: 'en',
                  getContainer: () => {
                    return document.querySelector('.fee-drawer-content') as HTMLElement;
                  },
                })
                  .then((token) => {
                    sendTransaction(token);
                  })
                  .catch((error) => {
                    console.log('getCloudflareTurnstileResponse error', error);
                    sendTransaction('');
                  })
                  .finally(() => {
                    setSendLoading(false);
                  });
              } else {
                sendTransaction();
              }
            }}
            disabled={selectFeeQuote === undefined}
          >
            {t('send')}
          </Button>
        </div>
        <div className="wallet_footer">
          <img className="logo_logo" src="/images/walletConnect/logo_logo.png" />
        </div>
      </div>
    );
  };

  return (
    <WModel
      className="erc4337-modal-container erc4337-transaction-container"
      visible={open}
      title={t('network_fees')}
      onClose={onClose}
      modalProps={{
        width: 500,
        style: {},
      }}
    >
      {contentBody()}
    </WModel>
  );
};

export default Erc4337GasModal;
