import { useRouter } from 'next/router';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface RouterHistory {
  history: string[];
  back: (path: string) => void;
}

const RHistoryContext = createContext<RouterHistory | undefined>(undefined);

interface RouterHistoryProviderProps {
  children: ReactNode;
}

let backTargetPath = '';

export const RouterHistoryProvider: React.FC<RouterHistoryProviderProps> = ({ children }) => {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>(() => {
    if (typeof window !== 'undefined') {
      const storedHistory = sessionStorage.getItem('routerHistory');
      return storedHistory ? JSON.parse(storedHistory) : [];
    }
    return [];
  });

  useEffect(() => {
    setHistory((prevHistory) => {
      const { pathname } = router;
      let newHistory = [...prevHistory];
      const pathIndex = prevHistory.indexOf(pathname);

      if (pathIndex > -1) {
        newHistory = prevHistory.slice(0, pathIndex + 1);
      } else {
        newHistory.push(pathname);
      }

      if (newHistory.includes('/') && newHistory.findIndex((url) => url === '/') !== 0) {
        newHistory = newHistory.slice(newHistory.findIndex((url) => url === '/'));
      }

      sessionStorage.setItem('routerHistory', JSON.stringify(newHistory));
      return newHistory;
    });
  }, [router.pathname]);

  const back = (path: string) => {
    path = path.split('.')[0];

    if (history[history?.length - 1] === path) {
      return;
    } else if (history?.length === 1 && history[0] === '/') {
      router.push(path);
      return;
    } else if (history?.length === 1 && history[0] !== '/' && path === '/') {
      router.replace('/');
      return;
    } else if (history?.length === 1 && history[0] !== '/' && path !== '/') {
      backTargetPath = path;
      router.replace('/');
      return;
    }

    backTargetPath = path;
    router.back();
  };

  useEffect(() => {
    setTimeout(() => {
      const pathname = location.pathname?.split?.('.')[0];
      const sessionHistory = JSON.parse(sessionStorage.getItem('allRouterHistory') || '[]');
      sessionHistory.push(pathname);
      sessionStorage.setItem('allRouterHistory', JSON.stringify(sessionHistory));

      if (!backTargetPath || router.pathname !== pathname) {
        backTargetPath = '';
        return;
      }

      const history = JSON.parse(sessionStorage.getItem('routerHistory') || '[]');

      if (history?.length === 1 && backTargetPath && history[0] !== backTargetPath) {
        router.push(backTargetPath);
        backTargetPath = '';
      } else if (history?.length > 1 && backTargetPath && backTargetPath !== pathname) {
        if (history[0] === '/') {
          router.back();
        } else {
          router.push(backTargetPath);
        }
      } else {
        backTargetPath = '';
      }
    });
  }, [router.pathname]);

  return <RHistoryContext.Provider value={{ history, back }}>{children}</RHistoryContext.Provider>;
};

export const useRouterHistory = () => {
  const context = useContext(RHistoryContext);
  if (context === undefined) {
    throw new Error('useRouterHistory must be used within a RouterHistoryProvider');
  }
  return context;
};
