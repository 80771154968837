/* eslint-disable */
import icon_img_error from './icon_img_error.svg';
import icon_transaction_approve from './icon_transaction_approve.svg';
import icon_transaction_burn from './icon_transaction_burn.svg';
import icon_transaction_buy from './icon_transaction_buy.svg';
import icon_transaction_cancel_listing from './icon_transaction_cancel_listing.svg';
import icon_transaction_check_in from './icon_transaction_check_in.svg';
import icon_transaction_default from './icon_transaction_default.svg';
import icon_transaction_listing from './icon_transaction_listing.svg';
import icon_transaction_mint from './icon_transaction_mint.svg';
import icon_transaction_receive from './icon_transaction_receive.svg';
import icon_transaction_sell from './icon_transaction_sell.svg';
import icon_transaction_send from './icon_transaction_send.svg';
import icon_transaction_swap from './icon_transaction_swap.svg';
import nft_icon from './nft_icon.svg';
import icon_arrow1 from './icon_arrow1.svg';
import icon_arrow2 from './icon_arrow2.svg';
import icon_3 from './icon_3.svg';
import icon_6 from './icon_6.svg';
import icon_7 from './icon_7.svg';
import icon_8 from './icon_8.svg';
import icon_9 from './icon_9.svg';
import icon_10 from './icon_10.svg';
import icon_11 from './icon_11.svg';
import icon_12 from './icon_12.svg';
import icon_13 from './icon_13.svg';
import icon_14 from './icon_14.svg';
import icon_16 from './icon_16.svg';
import icon_17 from './icon_17.svg';
import icon_18 from './icon_18.svg';
import icon_20 from './icon_20.svg';
import icon_21 from './icon_21.svg';
import icon_22 from './icon_22.svg';
import icon_23 from './icon_23.svg';
import icon_24 from './icon_24.svg';
import icon_25 from './icon_25.svg';
import icon_26 from './icon_26.svg';
import icon_27 from './icon_27.svg';
import icon_28 from './icon_28.svg';
import icon_30 from './icon_30.svg';
import icon_32 from './icon_32.svg';
import icon_33 from './icon_33.svg';
import icon_34 from './icon_34.svg';
import icon_35 from './icon_35.svg';
import icon_36 from './icon_36.svg';
import icon_37 from './icon_37.svg';
import icon_38 from './icon_38.svg';
import icon_39 from './icon_39.svg';
import icon_40 from './icon_40.svg';
import icon_41 from './icon_41.svg';
import icon_42 from './icon_42.svg';
import icon_43 from './icon_43.svg';
import icon_44 from './icon_44.svg';
import icon_45 from './icon_45.svg';
import icon_46 from './icon_46.svg';
import icon_47 from './icon_47.svg';
import icon_48 from './icon_48.svg';
import icon_49 from './icon_49.svg';
import icon_50 from './icon_50.svg';
import icon_51 from './icon_51.svg';
import icon_52 from './icon_52.svg';
import icon_53 from './icon_53.svg';
import icon_54 from './icon_54.svg';
import icon_55 from './icon_55.svg';
import icon_56 from './icon_56.svg';
import icon_57 from './icon_57.svg';
import icon_58 from './icon_58.svg';
import icon_59 from './icon_59.svg';
import icon_60 from './icon_60.svg';
import icon_61 from './icon_61.svg';
import icon_62 from './icon_62.svg';
import icon_63 from './icon_63.svg';
import icon_64 from './icon_64.svg';
import icon_65 from './icon_65.svg';
import icon_66 from './icon_66.svg';
import icon_67 from './icon_67.svg';
import icon_68 from './icon_68.svg';
import icon_69 from './icon_69.svg';
import icon_70 from './icon_70.svg';
import icon_71 from './icon_71.svg';
import icon_72 from './icon_72.svg';
import icon_73 from './icon_73.svg';
import icon_74 from './icon_74.svg';
import icon_75 from './icon_75.svg';
import icon_76 from './icon_76.svg';
import icon_77 from './icon_77.svg';
import icon_78 from './icon_78.svg';
import icon_79 from './icon_79.svg';
import icon_80 from './icon_80.svg';
import icon_81 from './icon_81.svg';

export const iconList = ['icon_img_error',
    'icon_transaction_approve',
    'icon_transaction_burn',
    'icon_transaction_buy',
    'icon_transaction_cancel_listing',
    'icon_transaction_check_in',
    'icon_transaction_default',
    'icon_transaction_listing',
    'icon_transaction_mint',
    'icon_transaction_receive',
    'icon_transaction_sell',
    'icon_transaction_send',
    'icon_transaction_swap',
    'nft_icon',
    'icon_arrow1',
    'icon_arrow2',
    'icon_3',
    'icon_6',
    'icon_7',
    'icon_8',
    'icon_9',
    'icon_10',
    'icon_11',
    'icon_12',
    'icon_13',
    'icon_14',
    'icon_16',
    'icon_17',
    'icon_18',
    'icon_20',
    'icon_21',
    'icon_22',
    'icon_23',
    'icon_24',
    'icon_25',
    'icon_26',
    'icon_27',
    'icon_28',
    'icon_30',
    'icon_32',
    'icon_33',
    'icon_34',
    'icon_35',
    'icon_36',
    'icon_37',
    'icon_38',
    'icon_39',
    'icon_40',
    'icon_41',
    'icon_42',
    'icon_43',
    'icon_44',
    'icon_45',
    'icon_46',
    'icon_47',
    'icon_48',
    'icon_49',
    'icon_50',
    'icon_51',
    'icon_52',
    'icon_53',
    'icon_54',
    'icon_55',
    'icon_56',
    'icon_57',
    'icon_58',
    'icon_59',
    'icon_60',
    'icon_61',
    'icon_62',
    'icon_63',
    'icon_64',
    'icon_65',
    'icon_66',
    'icon_67',
    'icon_68',
    'icon_69',
    'icon_70',
    'icon_71',
    'icon_72',
    'icon_73',
    'icon_74',
    'icon_75',
    'icon_76',
    'icon_77',
    'icon_78',
    'icon_79',
    'icon_80',
    'icon_81']

export default {
    icon_img_error,
    icon_transaction_approve,
    icon_transaction_burn,
    icon_transaction_buy,
    icon_transaction_cancel_listing,
    icon_transaction_check_in,
    icon_transaction_default,
    icon_transaction_listing,
    icon_transaction_mint,
    icon_transaction_receive,
    icon_transaction_sell,
    icon_transaction_send,
    icon_transaction_swap,
    nft_icon,
    icon_arrow1,
    icon_arrow2,
    icon_3,
    icon_6,
    icon_7,
    icon_8,
    icon_9,
    icon_10,
    icon_11,
    icon_12,
    icon_13,
    icon_14,
    icon_16,
    icon_17,
    icon_18,
    icon_20,
    icon_21,
    icon_22,
    icon_23,
    icon_24,
    icon_25,
    icon_26,
    icon_27,
    icon_28,
    icon_30,
    icon_32,
    icon_33,
    icon_34,
    icon_35,
    icon_36,
    icon_37,
    icon_38,
    icon_39,
    icon_40,
    icon_41,
    icon_42,
    icon_43,
    icon_44,
    icon_45,
    icon_46,
    icon_47,
    icon_48,
    icon_49,
    icon_50,
    icon_51,
    icon_52,
    icon_53,
    icon_54,
    icon_55,
    icon_56,
    icon_57,
    icon_58,
    icon_59,
    icon_60,
    icon_61,
    icon_62,
    icon_63,
    icon_64,
    icon_65,
    icon_66,
    icon_67,
    icon_68,
    icon_69,
    icon_70,
    icon_71,
    icon_72,
    icon_73,
    icon_74,
    icon_75,
    icon_76,
    icon_77,
    icon_78,
    icon_79,
    icon_80,
    icon_81
  };