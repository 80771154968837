import { TokenInfo, UniversalTransaction, isServer } from '@particle-network/wallet-core';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { AppParams } from '../utils';

interface UniversalAccount {
  /**
   * 是否是 UniversalAccount
   */
  isUniversalAccount: boolean;
  /**
   * 列表显示 AllNetworks 项目
   */
  displayAllNetworks: boolean;
  setDisplayAllNetworks: (display: boolean) => void;
  /**
   * 是否选中 AllNetworks
   */
  selectedGlobalChain: boolean;
  setSelectedGlobalChain: (selected: boolean) => void;

  uaBalance: string;
  setUaBalance: (balance: string) => void;

  walletAddress: string;
  setWalletAddress: (address: string) => void;

  universalTransactionList: UniversalTransaction[];
  setUniversalTransactionList: (list: UniversalTransaction[]) => void;
  universalTransactionLoading: boolean;
  setUniversalTransactionLoading: (loading: boolean) => void;
  universalTransactionRefresh: () => void;

  universalTokens: TokenInfo[];
  setUniversalTokens: (tokens: TokenInfo[]) => void;

  universalTokensLoading: boolean;
  setUniversalTokensLoading: (loading: boolean) => void;

  tokenPrice: {
    [key: string]: number;
  };
  setTokenPrice: (price: { [key: string]: number }) => void;
}

const UAccountContext = createContext<UniversalAccount | undefined>(undefined);

interface UAccountProviderProps {
  appParams: AppParams;
  children: ReactNode;
}

export const UniversalAccountProvider: React.FC<UAccountProviderProps> = ({ children, appParams }) => {
  const [isUniversalAccount, setIsUniversalAccount] = useState(false);

  const [displayAllNetworks, setDisplayAllNetworks] = useState(false);

  const [selectedGlobalChain, setSelectedGlobalChain] = useState(false);

  const [walletAddress, setWalletAddress] = useState<string>('');

  const [uaBalance, setUaBalance] = useState<string>('-1');

  const [universalTransactionList, setUniversalTransactionList] = useState<UniversalTransaction[]>([]);
  const [universalTransactionLoading, setUniversalTransactionLoading] = useState<boolean>(false);

  const [universalTokens, setUniversalTokens] = useState<TokenInfo[]>([]);
  const [universalTokensLoading, setUniversalTokensLoading] = useState<boolean>(true);

  const [tokenPrice, setTokenPrice] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    // @ts-ignore
    if (appParams?.erc4337?.name === 'UNIVERSAL') {
      setIsUniversalAccount(true);
      setDisplayAllNetworks(true);
    } else {
      setIsUniversalAccount(false);
      setDisplayAllNetworks(false);
    }
  }, [appParams?.erc4337]);

  useEffect(() => {
    if (universalTokens?.length) {
      const tokenPrice = Object.fromEntries(
        universalTokens
          .map((item) => item.metadata?.tokenBalances?.[0]?.token)
          .filter((item: any) => item?.type)
          .map((item) => {
            return [item.type, Number(item.exchangeRateToUSD || 0)];
          })
      );
      setTokenPrice(tokenPrice);
    }
  }, [universalTokens]);

  useEffect(() => {
    // @ts-ignore
    if (appParams?.erc4337?.name === 'UNIVERSAL') {
      setSelectedGlobalChain(true);
    } else {
      setSelectedGlobalChain(false);
    }

    // if (typeof window !== 'undefined' && location.href.includes('localhost')) {
    //   setDisplayAllNetworks(true);
    //   setSelectedGlobalChain(true);
    // }
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.__selectedGlobalChain__ = selectedGlobalChain;
    // @ts-ignore
    window.__tokenPrice__ = tokenPrice;
  }, [selectedGlobalChain, tokenPrice]);

  return (
    <UAccountContext.Provider
      value={{
        universalTransactionList,
        setUniversalTransactionList,
        universalTransactionLoading,
        setUniversalTransactionLoading,
        // @ts-ignore
        universalTransactionRefresh: isServer() ? () => {} : window.__universalTransactionRefresh__,
        isUniversalAccount,
        displayAllNetworks,
        setDisplayAllNetworks,
        selectedGlobalChain,
        setSelectedGlobalChain,
        walletAddress,
        setWalletAddress,
        uaBalance,
        setUaBalance,
        universalTokens,
        setUniversalTokens,
        universalTokensLoading,
        setUniversalTokensLoading,
        tokenPrice,
        setTokenPrice,
      }}
    >
      {children}
    </UAccountContext.Provider>
  );
};

export const useUniversalAccount = () => {
  const context = useContext(UAccountContext);
  if (context === undefined) {
    throw new Error('useUniversalAccount must be used within a UniversalAccountProvider');
  }
  return context;
};
