import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { SwtichNetworkStatus } from '@/context/WebWalletContext';
import { toChecksumAddress } from '@ethereumjs/util';
import { Auth, ParticleNetwork, UIMode, toBase58Address } from '@particle-network/auth';
import { ParticleChains } from '@particle-network/chains';
import type { EVMProvider, Provider, SolanaProvider } from '@particle-network/connect';
import { useAccount, useParticleProvider } from '@particle-network/connect-react-ui';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { ParticleProvider } from '@particle-network/provider';
import { SolanaWallet } from '@particle-network/solana-wallet';
import { EvmService, ParticleWallet, SolanaService } from '@particle-network/wallet-core';
import { useDeepCompareEffect } from 'ahooks';
import { useCallback, useState } from 'react';
import { AppParams, isServer, sleep } from '../utils';

const useCommon = (appParams: AppParams, webWalletData: any, authCoreSwitchChain: any) => {
  const [particleProvider, setParticleProvider] = useState<ParticleProvider | SolanaProvider | EVMProvider | SolanaWallet | null | undefined>(null);

  const [particleWalletService, setParticleWalletService] = useState<EvmService | SolanaService>();

  const provider: Provider | undefined = useParticleProvider();

  const [walletAddress, setWalletAddress] = useState<string | undefined>();

  const account = useAccount();

  const { selectedGlobalChain, setWalletAddress: setUniversalWalletAddress } = useUniversalAccount();

  const getParticleWalletService = useCallback((provider, webWalletData): EvmService | SolanaService => {
    if (!provider) {
      return {} as EvmService | SolanaService;
    }

    const options = {
      chainName: webWalletData.chainName,
      chainId: webWalletData.chainId,
      clientKey: webWalletData.clientKey,
      projectId: webWalletData.projectId,
      currency: webWalletData.currency || appParams.currency,
      appId: webWalletData.appId,
      mockEvmAddress: webWalletData.mockEvmAddress,
      mockSolanaAddress: webWalletData.mockSolanaAddress,
      t: isServer()
        ? (val: string) => {
            return val;
          }
        : window.t,
      accountAbstraction: webWalletData.accountAbstraction,
      events: webWalletData.events,
    };
    return ParticleWallet(provider, webWalletData.chainName, options);
  }, []);

  useDeepCompareEffect(() => {
    setTimeout(async () => {
      let _provider: ParticleProvider | SolanaWallet | Provider | any | undefined;
      const iframeId = window.storage.getItem('iframeid');
      let authCoreChainInfo = null;
      if (iframeId && appParams.inAuthCoreIframe) {
        if (webWalletData.chainName?.toLowerCase() === 'solana') {
          _provider = iframeWallet.solana as any;
        } else {
          _provider = iframeWallet.ethereum;
        }

        // authCore 首次 初始化 chainId
        if (!sessionStorage.getItem('authCoreInit')) {
          let chainId = webWalletData.chainId;
          let chainName = webWalletData.chainName;

          sessionStorage.setItem('authCoreInit', 'true');
          if (webWalletData.chainName?.toLowerCase() === 'solana') {
            chainId = await (_provider as any)?.getChainId();
            const chain = Object.values(ParticleChains).find((item) => item.id == Number(chainId));
            chainName = chain?.name || '';
            chainId = chain?.id.toString() || '';
          } else {
            chainId = await _provider?.request({ method: 'eth_chainId' });
            // 判断是否在 webWalletData.supportChains 里面，如果supportChains有值并且chainId不在里面，_provider 切链到supportChains 第一个
            if (webWalletData.supportChains && !webWalletData.supportChains?.find((item: any) => item.id == chainId)) {
              chainId = webWalletData.supportChains[0].id;
              await _provider?.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: webWalletData.supportChains[0].id }] });
            }
            const chain = Object.values(ParticleChains).find((item) => item.id == Number(chainId));
            chainName = chain?.name || '';
            chainId = chain?.id.toString() || '';
            authCoreChainInfo = chain;
          }

          if (!webWalletData.supportChains || (webWalletData.supportChains && webWalletData.supportChains?.find((item: any) => item.id == chainId))) {
            // @ts-ignore
            window.__updateWebWalletData({
              chainId,
              chainName,
            });
          }
        }
      } else if (appParams?.authUserInfo?.uuid) {
        const { projectId, clientKey, appId, chainName, chainId } = webWalletData || {};

        const params = {
          projectId,
          clientKey,
          appId,
          chainName,
          chainId: Number(chainId),
          wallet: {
            displayWalletEntry: false,
          },
          securityAccount: {
            promptSettingWhenSign: 1,
            promptMasterPasswordSettingWhenLogin: 2,
          },
        } as any;
        console.log('params', params);

        // @ts-ignore
        if (!window.isNewParticle) {
          window.particle = new ParticleNetwork(params);
          // @ts-ignore
          window.particle?.auth?.setUserInfo?.(appParams.authUserInfo);
          window.isNewParticle = true;
        }

        let flag = true;
        do {
          if (
            window.__webWalletData.chainName.toLowerCase() == 'solana' &&
            // @ts-ignore
            (window.particle?.config?.chainName?.toLowerCase() !== 'solana' || window.particle.auth.getChainId() !== Number(window.__webWalletData.chainId || '0'))
          ) {
            flag = true;
            await sleep();
          } else {
            flag = false;
          }
        } while (flag);

        window.particle?.setAuthTheme({
          uiMode: (appParams.theme as UIMode) ?? 'auto',
          displayCloseButton: true,
          ...(window.__appParams?.authTheme || {}),
        });

        if (!!appParams.authType) {
          // @ts-ignore
          window.particle?.auth?.setAuthType?.(appParams.authType ?? '');
        }

        if (webWalletData.language) {
          window.particle?.setLanguage(webWalletData.language);
        }

        if (webWalletData.chainName.toLowerCase().includes('solana')) {
          _provider = new SolanaWallet(window?.particle?.auth as Auth);
        } else {
          _provider = new ParticleProvider(window.particle?.auth as Auth);
        }
      } else {
        _provider = provider;
      }

      // @ts-ignore
      window.__updateWebWalletData({
        walletProvider: _provider,
      });

      setParticleProvider(_provider);

      if (webWalletData.inAuthCoreIframe && authCoreSwitchChain && authCoreChainInfo && authCoreChainInfo.chainType === 'evm') {
        if (Number(webWalletData.chainId) !== authCoreChainInfo?.id) {
          authCoreSwitchChain(authCoreChainInfo?.id);
        }
      }
    });
  }, [appParams, provider, webWalletData.chainName, webWalletData.chainId, webWalletData.language, webWalletData.supportChains]);

  useDeepCompareEffect(() => {
    if (
      webWalletData.swtichNetwork !== SwtichNetworkStatus.loading &&
      particleProvider &&
      webWalletData.chainName &&
      webWalletData.chainId &&
      (webWalletData.isLogin || account || appParams?.authUserInfo?.uuid)
    ) {
      if (
        // @ts-ignore
        (particleProvider && !particleProvider?.auth) ||
        // @ts-ignore
        (particleProvider?.auth &&
          // @ts-ignore
          ((particleProvider.auth.config.chainName.toLowerCase() == 'solana' && webWalletData.chainName.toLowerCase() == 'solana') ||
            // @ts-ignore
            (particleProvider.auth.config.chainName.toLowerCase() !== 'solana' && webWalletData.chainName.toLowerCase() != 'solana')))
      ) {
        if (particleProvider?.constructor?.name === 'SolanaWallet' && webWalletData.chainName.toLowerCase() !== 'solana') {
          return;
        }

        const service = getParticleWalletService(particleProvider, webWalletData);
        setParticleWalletService(service);

        service
          .getAddress()
          .then((account: string) => {
            let address = account;
            if (address && webWalletData.chainName.toLowerCase() == 'tron') {
              address = toBase58Address(account);
            } else if (address && webWalletData.chainName.toLowerCase() !== 'solana') {
              address = toChecksumAddress(address);
            }
            const omitAddress = (address || '').substring(0, 5) + '...' + (address || '').substring((address || '').length - 5);
            // @ts-ignore
            window.__updateWebWalletData({ omitAddress, copyAddress: address });
            setWalletAddress(address);

            if (selectedGlobalChain && webWalletData.isUniversal && address) {
              setUniversalWalletAddress(address);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [account, particleProvider, webWalletData.chainName, webWalletData.chainId, webWalletData.swtichNetwork, webWalletData.accountAbstraction, selectedGlobalChain]);

  // 从接口获取 区分大小写用于copy的address 和 omitAddress
  // useDebounceEffect(
  //   () => {
  //     console.log('useDebounceEffect __updateWebWalletData', particleWalletService?.chainType);
  //     if (
  //       (webWalletData.isLogin || account || appParams?.authUserInfo?.uuid) &&
  //       particleWalletService?.chainType &&
  //       webWalletData.chainInfo.chainName &&
  //       particleWalletService?.chainType?.toLowerCase?.() === webWalletData.chainInfo?.chainType?.toLowerCase?.() &&
  //       particleWalletService.getAddress
  //     ) {
  //       particleWalletService
  //         .getAddress()
  //         .then((account: string) => {
  //           let address = account;
  //           if (webWalletData.chainName.toLowerCase() == 'tron') {
  //             address = toBase58Address(account);
  //           } else if (webWalletData.chainInfo.chainType.toLowerCase() == 'evm') {
  //             address = toChecksumAddress(address);
  //           }
  //           const omitAddress = (address || '').substring(0, 5) + '...' + (address || '').substring((address || '').length - 5);
  //           // @ts-ignore
  //           window.__updateWebWalletData({ omitAddress, copyAddress: address });
  //           setWalletAddress(address);
  //         })
  //         .catch((err: any) => {
  //           console.log(err);
  //         });
  //     }
  //   },
  //   [account, particleWalletService, webWalletData.chainInfo, webWalletData.isLogin],
  //   {
  //     wait: 100,
  //   }
  // );

  return { particleProvider, particleWalletService, walletAddress, getParticleWalletService };
};

export default useCommon;
