const Loading = (props: any) => {
  const { spinning, position = '', copyright = false, className, displayMask = true } = props;
  return spinning ? (
    <div className={`loading-box ${position} ${className}`} data-mask={displayMask}>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {copyright && (
        <div>
          <div className="power-text">Powered by</div>
          <div className="logo-text">Particle Network</div>
        </div>
      )}
    </div>
  ) : null;
};

export default Loading;
