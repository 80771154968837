import { useWebWallet } from '@/context/WebWalletContext';
import { isSmallScreen, loadScript } from '@/utils/index';
import { isServer } from '@particle-network/wallet-core';
import { useSize } from 'ahooks';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import MIcon from '../mIcon';
import styles from './index.module.scss';

export const chatLogout = () => {
  try {
    const keyList = ['sdn_access_token', 'sdn_user_id', 'sdn_user_address', 'user_id', 'access_token', 'user_address'];
    keyList.map((key) => localStorage.removeItem(key));
    // @ts-ignore
    if (!isServer() && window?.chatWidgetApi?.logout) {
      // @ts-ignore
      window?.chatWidgetApi?.logout?.();
    }
  } catch (error) {}
};

const Chat = () => {
  const { webWalletData, t } = useWebWallet();
  const [visibleIcon, setVisibleIcon] = useState(false);
  const [visibleChatModal, setVisibleChatModal] = useState(false);
  const bodySize = useSize(() => document.querySelector('body'));
  const [isMobile, setIsMobile] = useState(false);
  const [userId, setUserId] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const themeConfigRef = useRef({} as any);
  const router = useRouter();

  useEffect(() => {
    if (isSmallScreen()) {
      setIsMobile(true);
    } else {
      setIsMobile(false);

      // 更新widgetHeight
      const widgetHeight = Math.min(window.innerHeight - 30 - 40 - 20, 680);
      themeConfigRef.current.widgetHeight = `${widgetHeight}px`;
    }
  }, [bodySize]);

  useEffect(() => {
    if (webWalletData.isLogin && !isMobile && webWalletData.chainInfo.chainType === 'evm' && scriptLoaded && webWalletData.chainName.toLowerCase() !== 'tron' && !webWalletData.isAA) {
      setVisibleIcon(true);
    } else {
      window.chatWidgetApi?.showWidget?.(false);
      setVisibleChatModal(false);
      setVisibleIcon(false);
    }
  }, [webWalletData.isLogin, isMobile, webWalletData.chainInfo.chainType, scriptLoaded, webWalletData.chainName, webWalletData.isAA]);

  useEffect(() => {
    if (webWalletData.isLogin && visibleChatModal && !userId) {
      const walletAddress = webWalletData.address;
      window.chatWidgetApi.thirdDIDLogin(
        walletAddress,
        async (res: { message: string }) => {
          // @ts-ignore
          const sign = await window.__particleProvider?.request?.({
            method: 'personal_sign',
            params: [res.message, walletAddress],
          });
          console.log('sign', sign);
          return sign;
        },
        () => {
          try {
            const userId = window.chatWidgetApi.getUserId();
            setUserId(userId);
          } catch (error) {
            console.log('error', error);
          }
        }
      );
    }
  }, [webWalletData.isLogin, visibleChatModal, userId]);

  useEffect(() => {
    if (visibleChatModal && router.pathname !== '/' && router.pathname !== 'index' && router.pathname !== 'index.html') {
      window.chatWidgetApi?.showWidget?.(false);
      setVisibleChatModal(false);
    }
  }, [router.pathname, visibleChatModal]);

  useEffect(() => {
    if (webWalletData.isLogin) {
      setTimeout(() => {
        if (!window.chatWidgetApi && !isMobile) {
          loadScript('/script/sdnChatWidget.js?_=0.1').then(() => {
            setScriptLoaded(true);
          });
        }
      });
    }
  }, [webWalletData.isLogin]);

  if (!visibleIcon) {
    return <></>;
  }

  return (
    <div className={styles.chat}>
      <div className={styles['chat-component-modal']}>
        {/* @ts-ignore */}
        <chat-component useThirdLogin={true} baseUrl="https://portal0101.sending.network" defaultShowWidget={false} {...themeConfigRef.current} />
      </div>
      <div
        className={`icon-button-default ${styles['chat-icon']}`}
        onClick={() => {
          const visible = !window.chatWidgetApi.widgetDisplay;
          window.chatWidgetApi.showWidget(visible);
          setVisibleChatModal(visible);
        }}
      >
        {visibleChatModal ? <MIcon name="icon_20" className="hidden-icon" size={17} /> : <MIcon name="icon_49" size={17} />}
      </div>
    </div>
  );
};

export default Chat;
