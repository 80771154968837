import { createCustomThemes, removeStyleElement } from '@/utils/createCustomThemes';
import { SmartAccount } from '@particle-network/aa';
import { ArbitrumOne, Aurora, Avalanche, BNBChain, Base, Ethereum, Fantom, Gnosis, Moonbeam, Moonriver, OKTC, Optimism, ParticleChains, Polygon, PolygonzkEVM } from '@particle-network/chains';
import { evmWallets, solanaWallets } from '@particle-network/connect';
import { urlCrypto } from '@particle-network/crypto';
import { iframeWallet } from '@particle-network/iframe-wallet';
import { AccountAbstractionType, aaOptions } from '@particle-network/wallet-core';
import { useSetState } from 'ahooks';
import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { AppParams, checkChainValid, formatChains, isServer, languages } from '../utils';

if (!isServer()) {
  // @ts-ignore
  window.__particle_aa__ = {
    SmartAccount,
  };
}

export const parseAppParams = (options: any): AppParams => {
  const defaultChainName = 'Ethereum';
  const defaultChainId = '1';
  const defaultTheme = options?.defaultTheme || 'dark';
  const defaultCurrency = 'USD';
  // const defaultLanguage = typeof window !== 'undefined' ? getNavigatorLanguage() : 'en-US';
  const defaultLanguage = 'en-US';

  const supportBridgeChains = [
    Ethereum.id,
    BNBChain.id,
    Polygon.id,
    Gnosis.id,
    Fantom.id,
    OKTC.id,
    Avalanche.id,
    ArbitrumOne.id,
    Optimism.id,
    Moonriver.id,
    Moonbeam.id,
    // Celo.id,
    // Cronos.id,
    Aurora.id,
    PolygonzkEVM.id,
    Base.id,
  ];

  if (isServer()) {
    return {
      appId: process.env.NEXT_PUBLIC_PROJECT_APP_ID as string,
      clientKey: process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY as string,
      projectId: process.env.NEXT_PUBLIC_PROJECT_ID as string,
      loginType: 'connect',
      chainName: defaultChainName,
      chainId: defaultChainId,
      paramsChainId: '',
      supportChains: null,
      inAuthCoreIframe: false,
      theme: defaultTheme,
      hideSuspiciousTokens: true,
      hideSuspiciousNfts: true,
      language: defaultLanguage,
      supportAddToken: true,
      evmSupportWalletConnect: true, // 是否支持wallet connect
      supportUIModeSwitch: true, // 是否支持切换dark/light模式
      supportLanguageSwitch: true, // 是否支持切换语言
      currency: defaultCurrency,
      defaultChainName,
      defaultChainId,
      supportBridgeChains,
      authType: '',
      authSDKVersion: '',
    };
  }

  const query = qs.parse(location.search?.substring(1) || '');

  let appId,
    clientKey,
    projectId,
    loginType: 'session' | 'connect' = 'connect',
    chainName = '',
    chainId = '',
    paramsChainId = '',
    themeKey,
    currency,
    authType,
    authSDKVersion,
    authUserInfo,
    inAuthCoreIframe,
    mockSolanaAddress,
    mockEvmAddress,
    showTestNetWork = false,
    supportAddToken = true,
    hideSuspiciousTokens = true,
    hideSuspiciousNfts = true,
    fiatCoin,
    priorityTokenAddresses = [], // 优先显示的token
    priorityNFTContractAddresses = [], // 优先显示的nft
    displayNFTContractAddresses = [], // 只显示的nft
    displayTokenAddresses = [], // 只显示的token
    evmSupportWalletConnect = true, // 是否支持wallet connect
    supportUIModeSwitch = true, // 是否支持切换dark/light模式
    supportLanguageSwitch = true, // 是否支持切换语言
    supportChains: any[] | null = null;

  let {
    params = '',
    __customStyle,
    theme,
    language,
    topMenuType,
    mock_address,
    mock_evm_address,
    mock_solana_address,
    erc4337,
    customStyle = {},
    customStyleSetting = 'false',
    authTheme,
    iframeid,
    projectConfig,
    supportCustomEvent = false,
  } = (query || {}) as any;

  if (supportCustomEvent === 'true') {
    supportCustomEvent = true;
  }

  if (params) {
    try {
      let data = JSON.parse(urlCrypto.decryptUrlParam(decodeURIComponent(params as string))) || {};
      appId = data.appId;
      clientKey = data.clientKey;
      projectId = data.projectId;
      chainName = data.chainName;
      chainId = data.chainId;
      supportChains = data.supportChains || null;
      authUserInfo = data.authUserInfo;
      authType = data.authType;
      authSDKVersion = data.authSDKVersion;

      sessionStorage.clear();
    } catch (error) {
      console.log(error);
    }
  } else if (iframeid && projectConfig) {
    const data = JSON.parse(Buffer.from(decodeURIComponent((projectConfig || '') as string), 'base64').toString('utf-8') || '{}');
    console.log('iframeid', iframeid, data, erc4337);

    appId = data.appId;
    clientKey = data.clientKey;
    projectId = data.projectId;
    authUserInfo = {
      uuid: '.',
    };

    sessionStorage.clear();

    window.storage.setItem('iframeid', iframeid || '');

    inAuthCoreIframe = true;
    console.log('AuthCore iframeWallet init');
    iframeWallet.init({
      iframeId: iframeid,
      evm: true,
      solana: true,
      supportCustomEvent,
    });

    // 删除本地存储的用户信息，有iframeid不需要本地存储用户信息
    // @ts-ignore
    const userInfoKey = `pn_auth_user_info_${appId}`;
    localStorage.removeItem(userInfoKey);
  }

  paramsChainId = chainId || window.storage.getItem('paramsChainId') || '';

  if (authTheme) {
    try {
      authTheme = JSON.parse(Buffer.from(authTheme, 'base64').toString('utf-8'));
    } catch (error) {
      console.log(error);
    }
  }

  if (query.debug) {
    if (query.appId) {
      appId = query.appId as string;
    }
    if (query.clientKey) {
      clientKey = query.clientKey as string;
    }
    if (query.projectId) {
      projectId = query.projectId as string;
    }
    if (query.chainName) {
      chainName = query.chainName as string;
    }
    if (query.chainId) {
      chainId = query.chainId as string;
    }
  }

  if (params && !customStyle && !isServer() && window.storage.getItem('customStyle')) {
    window.storage.removeItem('customStyle');
  }

  customStyle = customStyle || window.storage.getItem('customStyle') || '';
  if (customStyle) {
    try {
      window.storage.setItem('customStyle', customStyle);
      customStyle = JSON.parse(Buffer.from(customStyle, 'base64').toString());
    } catch (error) {
      console.log(error);
      customStyle = {};
    }
  }

  if (typeof customStyle !== 'object') {
    customStyle = {};
  }

  // TODO  测试自定义主题 start
  // if (!customStyle) {
  //   customStyle = {};
  // }
  // customStyle.dark = mockThemeDark;
  // TODO  测试自定义主题 end

  customStyleSetting = customStyleSetting || window.storage.getItem('customStyleSetting') || '';
  if (customStyleSetting && customStyleSetting === 'false') {
    customStyleSetting = '';
  }
  // 自定义主题
  if (customStyle?.dark || customStyle?.light) {
    createCustomThemes({
      dark: customStyle?.dark,
      light: customStyle.light,
    });
  } else if (!customStyleSetting) {
    removeStyleElement();
  }

  theme = theme?.toString().trim();

  if (!chainId) {
    chainName = '';
  }

  if (!supportChains && customStyle?.supportChains) {
    supportChains = customStyle?.supportChains;
  }

  supportChains =
    formatChains(supportChains)?.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    }) || null;

  // 如果chain没有数据，supportChains 有数据，会默认取第一个
  if (!customStyle?.chain?.name && supportChains?.length) {
    customStyle.chain = supportChains[0];
  }

  chainName = (chainName || customStyle?.chain?.name || window.storage.getItem('chainName') || defaultChainName || '') as string;
  chainId = (chainId || customStyle?.chain?.id || window.storage.getItem('chainId') || defaultChainId || '') as string;

  if (!checkChainValid(chainName, Number(chainId))) {
    const chain = Object.values(ParticleChains).find((item) => item.id == Number(chainId));
    chainName = chain?.name || '';
    chainId = chain?.id.toString() || '';
  }

  // if (supportChains?.length) {
  //   const ids = Object.values(ParticleChains);
  //   supportChains = supportChains.filter((id) => ids.includes(id));
  // }

  // 如果supportChains有值，但不存在 chainName和chainId 会默认取第一个
  if (supportChains && supportChains.length) {
    if (!supportChains.find((item) => item.name.toLowerCase() === chainName.toLowerCase() && item.id === chainId)) {
      chainName = supportChains[0].name;
      chainId = supportChains[0].id;
    }
  }

  if (!chainId) {
    chainId = defaultChainId;
  }

  // 再次校验 chainName 和 chainId
  if (!checkChainValid(chainName, Number(chainId))) {
    const chain = Object.values(ParticleChains).find((item) => item.id == Number(chainId));
    chainName = chain?.name || '';
    chainId = chain?.id.toString() || '';
  }

  if (!chainId) {
    throw new Error('ChainId is required');
  }

  appId = (appId || (window?.useSessionStorage ? window.storage.getItem('appId') : '') || process.env.NEXT_PUBLIC_PROJECT_APP_ID || '') as string;
  clientKey = (clientKey || (window?.useSessionStorage ? window.storage.getItem('clientKey') : '') || process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY || '') as string;
  projectId = (projectId || (window?.useSessionStorage ? window.storage.getItem('projectId') : '') || process.env.NEXT_PUBLIC_PROJECT_ID || '') as string;

  supportChains = supportChains || (window.storage.getItem('supportChains') ? JSON.parse(window.storage.getItem('supportChains') as string) : null);

  themeKey = `theme_${appId}`;
  theme = (theme || window.storage.getItem(themeKey) || window.storage.getItem('themeMode') || defaultTheme || '') as 'auto' | 'dark' | 'light';

  hideSuspiciousTokens = window.storage.getItem('hideSuspiciousTokens') === 'false' ? false : true;
  hideSuspiciousNfts = window.storage.getItem('hideSuspiciousNfts') === 'false' ? false : true;

  language = (language || window.storage.getItem('language') || defaultLanguage || '') as string;
  language = (language?.replace?.(/_/g, '-') || '').toLowerCase();
  language = languages.find((item) => item.value.toLowerCase().includes(((language || '') as string).toLowerCase()))?.value || defaultLanguage;

  fiatCoin = customStyle?.fiatCoin || window.storage.getItem('fiatCoin') || '';

  supportAddToken =
    customStyle?.supportAddToken ?? (window.storage.getItem('supportAddToken') ? window.storage.getItem('supportAddToken') == 'true' : window.storage.getItem('supportAddToken')) ?? true;

  evmSupportWalletConnect =
    customStyle?.evmSupportWalletConnect ??
    (window.storage.getItem('evmSupportWalletConnect') ? window.storage.getItem('evmSupportWalletConnect') == 'true' : window.storage.getItem('evmSupportWalletConnect')) ??
    true;

  supportUIModeSwitch =
    customStyle?.supportUIModeSwitch ??
    (window.storage.getItem('supportUIModeSwitch') ? window.storage.getItem('supportUIModeSwitch') == 'true' : window.storage.getItem('supportUIModeSwitch')) ??
    true;

  supportLanguageSwitch =
    customStyle?.supportLanguageSwitch ??
    (window.storage.getItem('supportLanguageSwitch') ? window.storage.getItem('supportLanguageSwitch') == 'true' : window.storage.getItem('supportLanguageSwitch')) ??
    true;

  currency = fiatCoin || ((window.storage.getItem('currency') || defaultCurrency || '') as string);

  showTestNetWork = !!(window.storage.getItem('showTestNetWork') || false);

  authUserInfo = authUserInfo || (window.storage.getItem('authUserInfo') ? JSON.parse(window.storage.getItem('authUserInfo') as string) : {});

  loginType = authUserInfo?.uuid ? 'session' : 'connect';

  topMenuType = topMenuType as string;

  mockEvmAddress = (mock_evm_address || mockEvmAddress || window.storage.getItem('mockEvmAddress') || '') as string;
  mockSolanaAddress = (mock_solana_address || mockSolanaAddress || window.storage.getItem('mockSolanaAddress') || '') as string;

  if (loginType === 'session') {
    erc4337 = erc4337 ?? customStyle?.erc4337 ?? window.storage.getItem('erc4337') ?? undefined;
    if ((typeof erc4337 === 'boolean' && erc4337) || erc4337 === 'true') {
      erc4337 = {
        name: 'BICONOMY',
        version: '1.0.0',
      };
    } else if (typeof erc4337 === 'string') {
      erc4337 = JSON.parse(erc4337);
    } else {
      erc4337 = undefined;
    }
  } else {
    erc4337 = undefined;
  }

  // 如何判断chainId是否支持erc4337, accountAbstractionChainIds number[]   start
  // 如果 erc4337=true supportChains 有值 切 supportChains 的链中没有支持的链，erc4337 = undefined;
  // 如果 erc4337=true,但 chainId 不支持erc4337,chainName 和 chainId 取 supportChains 中第一个支持的链
  if (erc4337 && !iframeid) {
    let aaNetworkConfig: number[];
    if (erc4337.name === AccountAbstractionType.CYBERCONNECT) {
      aaNetworkConfig = aaOptions.accountContracts.CYBERCONNECT.find((item) => item.version === (erc4337.version || '1.0.0'))?.chainIds || [];
    } else if (erc4337.name === AccountAbstractionType.SIMPLE) {
      aaNetworkConfig = aaOptions.accountContracts.SIMPLE.find((item) => item.version === (erc4337.version || '1.0.0'))?.chainIds || [];
    } else {
      aaNetworkConfig = aaOptions.accountContracts.BICONOMY.find((item) => item.version === (erc4337.version || '1.0.0'))?.chainIds || [];
    }
    const firstSupportChain = supportChains?.find?.((item) => {
      if (aaNetworkConfig.includes(Number(item.id))) {
        return item;
      }
    });
    const supportQueryChainId = aaNetworkConfig.includes(Number(chainId));
    if (supportChains?.length && !firstSupportChain) {
      erc4337 = false;
    } else if (!supportQueryChainId && firstSupportChain) {
      chainName = firstSupportChain.name;
      chainId = firstSupportChain.id;
    } else if (!supportQueryChainId) {
      erc4337 = false;
    }
  }
  // 如何判断chainId是否支持erc4337, accountAbstractionChainIds number[]   end

  if (mock_address == 'false') {
    mockSolanaAddress = '';
    mockEvmAddress = '';
  }

  displayTokenAddresses = (customStyle?.displayTokenAddresses || JSON.parse(window.storage.getItem('displayTokenAddresses') || '[]') || []).map((address: string) => address.toLowerCase());
  displayNFTContractAddresses = (customStyle?.displayNFTContractAddresses || JSON.parse(window.storage.getItem('displayNFTContractAddresses') || '[]') || []).map((address: string) =>
    address.toLowerCase()
  );

  // priorityTokenAddresses
  priorityTokenAddresses = (customStyle?.priorityTokenAddresses || JSON.parse(window.storage.getItem('priorityTokenAddresses') || '[]') || [])
    .map((address: string) => address.toLowerCase())
    .filter((address: string) => !!address);

  // priorityNFTContractAddresses
  priorityNFTContractAddresses = (customStyle?.priorityNFTContractAddresses || JSON.parse(window.storage.getItem('priorityNFTContractAddresses') || '[]') || [])
    .map((address: string) => address.toLowerCase())
    .filter((address: string) => !!address);

  authTheme = authTheme || JSON.parse(window.storage.getItem('authTheme') || '{}');

  inAuthCoreIframe = inAuthCoreIframe ?? window.storage.getItem('inAuthCoreIframe') === 'true';

  window.storage.setItem('chainName', chainName);
  window.storage.setItem('chainId', chainId);
  window.storage.setItem('language', language);
  window.storage.setItem('mockEvmAddress', mockEvmAddress);
  window.storage.setItem('mockSolanaAddress', mockSolanaAddress);
  window.storage.setItem('fiatCoin', fiatCoin || '');
  window.storage.setItem('customStyleSetting', customStyleSetting || '');
  window.storage.setItem('erc4337', JSON.stringify(erc4337 || ''));
  window.storage.setItem('supportLanguageSwitch', supportLanguageSwitch ? 'true' : 'false');
  window.storage.setItem('evmSupportWalletConnect', evmSupportWalletConnect ? 'true' : 'false');
  window.storage.setItem('supportUIModeSwitch', supportUIModeSwitch ? 'true' : 'false');
  window.storage.setItem('displayNFTContractAddresses', displayNFTContractAddresses ? JSON.stringify(displayNFTContractAddresses) : '');
  window.storage.setItem('priorityNFTContractAddresses', priorityNFTContractAddresses ? JSON.stringify(priorityNFTContractAddresses) : '');
  window.storage.setItem('priorityTokenAddresses', priorityTokenAddresses ? JSON.stringify(priorityTokenAddresses) : '');
  window.storage.setItem('displayTokenAddresses', displayTokenAddresses ? JSON.stringify(displayTokenAddresses) : '');
  window.storage.setItem('supportAddToken', supportAddToken ? 'true' : 'false');
  window.storage.setItem('appId', appId);
  window.storage.setItem('clientKey', clientKey);
  window.storage.setItem('projectId', projectId);
  window.storage.setItem('supportChains', supportChains ? JSON.stringify(supportChains) : '');
  window.storage.setItem('authUserInfo', authUserInfo ? JSON.stringify(authUserInfo || {}) : '');
  window.storage.setItem('authTheme', JSON.stringify(authTheme || {}));
  window.storage.setItem('paramsChainId', paramsChainId);
  window.storage.setItem('hideSuspiciousTokens', hideSuspiciousTokens ? 'true' : 'false');
  window.storage.setItem('hideSuspiciousNfts', hideSuspiciousNfts ? 'true' : 'false');
  window.storage.setItem('inAuthCoreIframe', (!!inAuthCoreIframe).toString());

  if (themeKey) {
    window.storage.setItem(themeKey, theme);
  }
  window.storage.setItem('currency', currency);

  const appParams: AppParams = {
    appId,
    clientKey,
    projectId,
    loginType,
    chainName,
    chainId,
    paramsChainId,
    supportChains,
    themeKey,
    theme,
    hideSuspiciousTokens,
    hideSuspiciousNfts,
    language,
    currency,
    showTestNetWork,
    inAuthCoreIframe,
    defaultChainName,
    defaultChainId,
    authUserInfo,
    supportAddToken,
    evmSupportWalletConnect,
    supportUIModeSwitch,
    supportLanguageSwitch,
    topMenuType,
    displayTokenAddresses,
    displayNFTContractAddresses,
    priorityTokenAddresses,
    priorityNFTContractAddresses,
    fiatCoin,
    erc4337,
    mockEvmAddress,
    mockSolanaAddress,
    customStyle,
    customStyleSetting,
    authTheme,
    supportBridgeChains,
    authType,
    authSDKVersion,
    supportCustomEvent,
  };

  return appParams;
};

let initAppParams: any = null;

const useAppParams = (options: any) => {
  if (!initAppParams) {
    initAppParams = parseAppParams(options);
  }

  const [appParams, setAppParams] = useSetState<AppParams>(initAppParams);

  useEffect(() => {
    if (appParams.inAuthCoreIframe && iframeWallet.ethereum) {
      console.log('AuthCore iframeWallet check whether support evm.');
      iframeWallet.ethereum.request({ method: 'eth_chainId' }).catch(() => {
        if (appParams.chainName?.toLowerCase() !== 'solana') {
          setAppParams({
            chainName: 'Solana',
            chainId: 101,
          });
        }
      });
    }
  }, [appParams.inAuthCoreIframe]);

  const modalProviderParams = useMemo(() => {
    const { appId, clientKey, projectId, theme } = appParams;

    const thirdartyProjectId = '21d2a01621c47fb5f34b06c6390ac0bb';
    const wallets = [...evmWallets({ projectId: thirdartyProjectId, showQrModal: false }), ...solanaWallets()];

    return {
      walletSort: ['Particle Auth', 'Wallet'],
      theme: theme as 'dark' | 'auto' | 'light',
      particleAuthSort: ['email', 'phone', 'google', 'apple', 'twitter', 'facebook', 'twitch', 'microsoft', 'linkedin', 'github', 'discord'],
      options: {
        projectId: projectId as string,
        clientKey: clientKey as string,
        appId: appId as string,
        chains: isServer() ? [] : Object.entries(ParticleChains).map((item: any) => ({ id: item[1].id, name: item[1].name })),
        // .filter((item) => {
        //   return item.name.toLowerCase() !== 'tron';
        // })
        particleWalletEntry: {
          displayWalletEntry: false,
        },
        wallets,
        securityAccount: {
          promptSettingWhenSign: 1,
          promptMasterPasswordSettingWhenLogin: 2,
        },
      },
    };
  }, [appParams.appId, appParams.clientKey, appParams.projectId, appParams.theme]);

  const updateAppParams = (params: any) => {
    Object.keys(params).forEach((key) => {
      window.storage.setItem(key, params[key]);
    });

    setAppParams((val) => {
      return {
        ...val,
        ...params,
      };
    });
  };

  if (!isServer()) {
    // @ts-ignore
    window.__updateAppParams = updateAppParams;
    // @ts-ignore
    window.__appParams = appParams;
  }

  // const { setSelectedGlobalChain } = useUniversalAccount();

  // useEffect(() => {
  //   if (initAppParams.erc4337.name === 'UNIVERSAL') {
  //     setSelectedGlobalChain(true);
  //   }
  // }, [initAppParams]);

  return {
    appParams,
    updateAppParams,
    modalProviderParams,
  };
};

export default useAppParams;
