import { SwtichNetworkStatus, useWebWallet } from '@/context/WebWalletContext';
import { Button } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WModel from '../WModel';
import { clientIcon } from '../sessionRequest';

const SwitchChain = (props: any) => {
  const { t } = useTranslation();

  const { webWalletData, updateWebWalletData, walletconnectData, updateWalletconnectData } = useWebWallet();

  const onClose = () => {
    webWalletData.wc2Manager?.rejectSwitchChain(walletconnectData.session!.peerId!);
    updateWalletconnectData();
  };

  const onSwitch = () => {
    const chain = webWalletData.particleWallet.getChainById(walletconnectData.chainId!)!;
    const chainId = chain.id.toString();
    const chainName = chain.name;

    updateWebWalletData({
      chainId,
      chainName,
      swtichNetwork: SwtichNetworkStatus.loading,
    });

    webWalletData.wc2Manager?.approveSwitchChain(walletconnectData.session!.peerId!);
    updateWalletconnectData();
  };

  const walletConnectIcon = useMemo(() => {
    return clientIcon(webWalletData, walletconnectData);
  }, [walletconnectData.session, walletconnectData?.peerMeta, webWalletData.theme]);

  const getDefaultImage = (event: any) => {
    event.currentTarget.onerror = null; // prevents looping
    event.currentTarget.src = webWalletData.theme === 'light' ? '/images/walletConnect/icon_placeholder_fff.png' : '/images/walletConnect/icon_placeholder.png';
  };

  const currentChain = () => {
    if (!webWalletData?.particleWallet?.getChainById || !webWalletData.particleWallet.chainId) {
      return <></>;
    }
    const chain = webWalletData.particleWallet.getChainById(webWalletData.particleWallet.chainId)!;
    return (
      <div className="left">
        <img className="img" src={chain?.icon} />
        <div className="switch_word_left">{chain?.fullname?.replace?.('Mainnet', '')?.trim?.()}</div>
      </div>
    );
  };

  const targetChain = () => {
    if (!webWalletData?.particleWallet?.getChainById || !walletconnectData?.chainId) {
      return <></>;
    }
    const chain = webWalletData.particleWallet.getChainById(walletconnectData?.chainId!)!;
    return (
      <div className="right">
        <img className="img" src={chain?.icon} />
        <div className="switch_word_right">{chain?.fullname?.replace?.('Mainnet', '')?.trim?.()}</div>
      </div>
    );
  };

  return (
    <WModel className="switch-chain-container" visible={walletconnectData.displaySwitchChain} onClose={onClose}>
      <div className="content">
        <div className="logo">
          <img className="img" src={walletConnectIcon} onError={getDefaultImage} />
        </div>
        <div className="website_name">{walletconnectData.session?.peerMeta?.name}</div>
        <div className="title">{t('allow_this_site')}</div>
        <div className="switch">
          {currentChain()}
          <img className="center" src="/images/walletConnect/line_light.png" />
          {targetChain()}
        </div>
        <div className="buttons">
          <Button onClick={onClose}>{t('cancel')}</Button>
          <Button type="primary" onClick={onSwitch}>
            {t('switch')}
          </Button>
        </div>
        <div className="wallet_footer">
          <img className="logo_logo" src="/images/walletConnect/logo_logo.png" />
        </div>
      </div>
    </WModel>
  );
};

export default SwitchChain;
