import { useWebWallet } from '@/context/WebWalletContext';
import { isServer } from '@/utils/env-util';
import { getWalletConnectIcon } from '@/utils/index';
import { Button, Image } from 'antd';
import blockies from 'ethereum-blockies';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import WModel from '../WModel';

export const clientIcon = (webWalletData: any, walletconnectData: any) => {
  if (walletconnectData.session && walletconnectData.session.peerMeta) {
    const icon = getWalletConnectIcon(walletconnectData.session?.peerMeta?.name);
    if (icon) {
      return icon;
    }
    if (walletconnectData.session.peerMeta?.icons?.length > 0) {
      return walletconnectData.session?.peerMeta?.icons[0];
    }
    if (walletconnectData.session.peerMeta.url) {
      return `${walletconnectData.session.peerMeta.url}/favicon.ico`;
    }
  }
  // @ts-ignore
  if (walletconnectData?.peerMeta?.icons?.length > 0) {
    // @ts-ignore
    return walletconnectData.peerMeta.icons[0];
  }
  // @ts-ignore
  if (walletconnectData?.peerMeta?.url) {
    // @ts-ignore
    return `${walletconnectData?.peerMeta?.url}/favicon.ico`;
  }
  return webWalletData.theme === 'light' ? '/images/walletConnect/icon_placeholder_fff.png' : '/images/walletConnect/icon_placeholder.png';
};

const SessionRequest = () => {
  const { t } = useTranslation();

  const { webWalletData, walletconnectData, updateWalletconnectData } = useWebWallet();

  const onClose = () => {
    webWalletData.wc2Manager?.rejectSessionRequest(walletconnectData.session!.peerId!);
    updateWalletconnectData();
  };

  const onConnect = () => {
    webWalletData.wc2Manager?.approveSessionRequest(walletconnectData.session!.peerId!);
    updateWalletconnectData();
  };

  const walletConnectIcon = useMemo(() => {
    return clientIcon(webWalletData, walletconnectData);
  }, [walletconnectData.session, walletconnectData?.peerMeta, webWalletData.theme]);

  const addressIcon = useMemo(() => {
    if ((!isServer() && walletconnectData.displaySessionRequest, webWalletData.copyAddress)) {
      const img = blockies
        .create({
          seed: webWalletData.copyAddress,
          size: 5,
          scale: 10,
        })
        .toDataURL();
      return img;
    } else {
      return '';
    }
  }, [walletconnectData.displaySessionRequest, webWalletData.copyAddress]);

  const websiteName = useMemo(() => {
    return walletconnectData.session?.peerMeta?.name?.split('|')?.[0] || walletconnectData.session?.peerMeta?.url || walletconnectData.session?.peerMeta?.description;
  }, [walletconnectData.session?.peerMeta?.url || walletconnectData.session?.peerMeta?.name || walletconnectData.session?.peerMeta?.description]);

  return (
    <WModel className="wallet-connect-container" visible={walletconnectData.displaySessionRequest} onClose={onClose}>
      <div className="content">
        <div className="close" onClick={onClose}></div>
        <div className="logo">
          <Image
            className="img"
            width={58}
            height={58}
            src={walletConnectIcon}
            fallback={webWalletData.theme === 'light' ? '/images/walletConnect/icon_placeholder_fff.png' : '/images/walletConnect/icon_placeholder.png'}
            preview={false}
          />
        </div>
        <div className="website_name">{websiteName}</div>
        <div className="title">{t('connect_to')}</div>
        <div className="desc">{t('allow_this')}</div>
        <div className="wallet_info_all">
          <div className="wallet_info">
            <div className="wallet_info_left">
              <img className="img" key={addressIcon} src={addressIcon} />
            </div>
            <div className="wallet_info_right">
              <div className="right_wallet_name">Particle Wallet</div>
              <div className="right_address">{webWalletData.omitAddress}</div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <Button onClick={onClose}>{t('cancel')}</Button>
          <Button onClick={onConnect} type="primary">
            {t('connect')}
          </Button>
        </div>
        <div className="wallet_footer">
          <img className="logo_logo" src="/images/walletConnect/logo_logo.png" />
        </div>
      </div>
    </WModel>
  );
};

export default SessionRequest;
