import { useWebWallet } from '@/context/WebWalletContext';
import { createCustomThemes } from '@/utils/createCustomThemes';
import { UIMode, WalletEntryPosition } from '@particle-network/auth';
import { useSetState, useThrottleEffect } from 'ahooks';
import { Popover, Tabs, Tooltip, message } from 'antd';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import MIcon from '../mIcon';

const defaultConfig: any = {
  projectId: '',
  clientKey: '',
  appId: '',
  chainName: '',
  chainId: 5,
  wallet: {
    displayWalletEntry: true,
    defaultWalletEntryPosition: WalletEntryPosition.BL,
    supportChains: [
      {
        id: 5,
        name: 'Ethereum',
      },
    ],
    customStyle: {
      supportAddToken: true,
      chain: {
        id: 1,
        name: 'Ethereum',
      },
      supportChains: [],
      displayTokenAddresses: [],
      displayNFTContractAddresses: [],
      fiatCoin: 'USD',
      evmSupportWalletConnect: true,
      supportUIModeSwitch: true,
      supportLanguageSwitch: true,
      light: {
        colorAccent: '#386ef2', // 高光颜色
        colorPrimary: '#ffffff', // 主要内容背景颜色  例：页面内容背景色
        colorOnPrimary: '#ffffff', // 信息面板背景颜色  例：首页信息块背景色 , switch chain list 背景色
        primaryButtonBackgroundColors: ['#000000', '#000000'], // 主要按钮颜色
        primaryButtonTextColor: '#ffffff', // 主要按钮字体颜色
        primaryIconButtonBackgroundColors: ['#dfe9fd', '#dfe9fd'], // 高光Icon按钮颜色
        cancelButtonBackgroundColor: '#666666', // 取消按钮颜色
        backgroundColors: [
          '#e5e5e5',
          [
            ['#ffffff00', '#ffffff00'],
            ['#ffffff00', '#ffffff00'],
          ],
        ],
        messageColors: ['#7DD5F9', '#ed5d51'], // 消息背景颜色 [0:成功消息, 1:错误消息]
        borderGlowColors: ['#7bd5f940', '#323233'], // 边框发光颜色 [0:亮色, 1:主色,]
        modalMaskBackgroundColor: '#141430b3', // 模态框遮罩层背景颜色
      },
      dark: {
        colorAccent: '#7DD5F9', // 高光颜色  例：dark模式的粉色，
        colorPrimary: '#21213a', // 主要内容背景颜色  例：页面内容背景色
        colorOnPrimary: '#171728', // 信息面板背景颜色  例：首页信息块背景色 , switch chain list 背景色
        primaryButtonBackgroundColors: ['#5ED7FF', '#E89DE7'], // 主要按钮颜色
        primaryIconButtonBackgroundColors: ['#5ED7FF', '#E89DE7'], // 高光Icon按钮颜色
        primaryButtonTextColor: '#0A1161', // 主要按钮字体颜色
        cancelButtonBackgroundColor: '#666666', // 取消按钮颜色
        backgroundColors: [
          '#14152e',
          [
            ['#e6b1f766', '#e6b1f700'],
            ['#7dd5f94d', '#7dd5f900'],
          ],
        ],
        messageColors: ['#7DD5F9', '#ed5d51'], // 消息背景颜色 [0:成功消息, 1:错误消息]
        borderGlowColors: ['#7bd5f940', '#323233'], // 边框发光颜色 [0:亮色, 1:主色,]
        modalMaskBackgroundColor: '#141430b3', // 模态框遮罩层背景颜色
      },
    },
  },
};

/**
 * 对象转换为key数组，多级对象转换为一级对象，key为路径 数组
 * @returns any[]
 */
export const objectToKeyArray = (obj: any, path: string = '') => {
  let result: any[] = [];
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      result = result.concat(objectToKeyArray(obj[key], path ? path + '.' + key : key));
    } else {
      result.push(path ? path + '.' + key : key);
    }
  }
  return result;
};

interface Iprops {
  customStyle: any;
  setCustomStyle: (val: any) => void;
  label: string;
  values: any[];
  activeKey?: string;
}

enum TabKey {
  Dark = 'dark',
  Light = 'light',
  Json = 'json',
}

const ColorItem = (props: Iprops) => {
  const { webWalletData, updateWebWalletData } = useWebWallet();
  const { customStyle, setCustomStyle, label, values, activeKey } = props;
  const [tooltipVisible, setTooltipVisible] = useState(new Array(values.length).fill(false));
  const ChromePicker = dynamic(() => import('react-color').then((mod) => mod.ChromePicker), {
    ssr: false,
  });

  return (
    <div className="item">
      <div className="label">{label}</div>
      {
        <div className="color-box">
          {values.map((item, index) => {
            return (
              <Popover
                key={item.path}
                overlayClassName="color-popover"
                arrowPointAtCenter={true}
                onOpenChange={(val) => {
                  if (val === false) {
                    tooltipVisible[index] = false;
                    setTooltipVisible([...tooltipVisible]);
                  }
                }}
                content={
                  <>
                    <ChromePicker
                      color={item.value}
                      onChange={(val) => {
                        const { r, g, b, a } = val.rgb;
                        const rgba = `rgba(${r},${g},${b},${a})`;
                        let pathArr = item.path.split('.');
                        let evalValue = 'customStyle';
                        for (let i = 0; i < pathArr.length; i++) {
                          const key = pathArr[i];
                          if (isNaN(key)) {
                            evalValue += `["${pathArr[i]}"]`;
                          } else {
                            evalValue += `[${pathArr[i]}]`;
                          }
                        }

                        if (a === 0) {
                          tooltipVisible[index] = true;
                          setTooltipVisible([...tooltipVisible]);
                        } else {
                          tooltipVisible[index] = false;
                          setTooltipVisible([...tooltipVisible]);
                        }

                        eval(`${evalValue}="${rgba}"`);
                        setCustomStyle(customStyle);
                      }}
                    />
                  </>
                }
                title=""
                trigger="click"
              >
                <Tooltip placement="bottom" title={'Alpha is 0, so it is not displayed'} open={tooltipVisible[index]}>
                  <div
                    className="color"
                    data-color={item.value}
                    data-path={item.path}
                    onClick={(e) => {
                      if (activeKey) {
                        if ((activeKey == TabKey.Dark && webWalletData.theme !== ('dark' as UIMode)) || (activeKey == TabKey.Light && webWalletData.theme !== ('light' as UIMode))) {
                          // @ts-ignore
                          window.__switchTheme();
                        }
                      }
                    }}
                    style={{
                      background: item.value,
                    }}
                  />
                </Tooltip>
              </Popover>
            );
          })}
        </div>
      }
    </div>
  );
};

const Content = () => {
  const { webWalletData, updateWebWalletData, t } = useWebWallet();
  const [customStyle, setCustomStyle] = useSetState({
    // @ts-ignore
    light: window.__appParams?.customStyle?.light || defaultConfig.wallet.customStyle.light,
    // @ts-ignore
    dark: window.__appParams?.customStyle?.dark || defaultConfig.wallet.customStyle.dark,
  });
  const [activeKey, setActiveKey] = useState(TabKey.Light);

  useThrottleEffect(
    () => {
      createCustomThemes(customStyle);
    },
    [customStyle],
    {
      wait: 300,
    }
  );

  useEffect(() => {
    if (activeKey) {
      if ((activeKey == TabKey.Dark && webWalletData.theme !== ('dark' as UIMode)) || (activeKey == TabKey.Light && webWalletData.theme !== ('light' as UIMode))) {
        // @ts-ignore
        window.__switchTheme();
      }
    }
  }, [activeKey]);

  useEffect(() => {
    if (webWalletData.theme) {
      setActiveKey(webWalletData.theme as TabKey);
    }
  }, []);

  return (
    <>
      <div className="setting-container">
        <div className="setting-form">
          <Tabs
            activeKey={activeKey}
            centered={true}
            onChange={(tabActiveKey) => {
              setActiveKey(tabActiveKey as TabKey);
            }}
            items={[
              {
                label: 'Light',
                key: TabKey.Light,
                children: (
                  <div className="tab-content">
                    <div className="color-list">
                      {/* ColorAccent */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorAccent"
                        values={[{ value: customStyle.light.colorAccent, path: 'light.colorAccent' }]}
                      />
                      {/* ColorPrimary */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorPrimary"
                        values={[{ value: customStyle.light.colorPrimary, path: 'light.colorPrimary' }]}
                      />
                      {/* primaryButtonTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryButtonTextColor"
                        values={[{ value: customStyle.light.primaryButtonTextColor, path: 'light.primaryButtonTextColor' }]}
                      />
                      {/* colorOnPrimary */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorOnPrimary"
                        values={[{ value: customStyle.light.colorOnPrimary, path: 'light.colorOnPrimary' }]}
                      />
                      {/* primaryButtonBackgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryButtonBackgroundColors"
                        values={[
                          {
                            value: customStyle.light.primaryButtonBackgroundColors[0],
                            path: 'light.primaryButtonBackgroundColors.0',
                          },
                          {
                            value: customStyle.light.primaryButtonBackgroundColors[1],
                            path: 'light.primaryButtonBackgroundColors.1',
                          },
                        ]}
                      />
                      {/* primaryIconButtonBackgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryIconButtonBackgroundColors"
                        values={[
                          {
                            value: customStyle.light.primaryIconButtonBackgroundColors[0],
                            path: 'light.primaryIconButtonBackgroundColors.0',
                          },
                          {
                            value: customStyle.light.primaryIconButtonBackgroundColors[1],
                            path: 'light.primaryIconButtonBackgroundColors.1',
                          },
                        ]}
                      />
                      {/* primaryIconTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryIconTextColor"
                        values={[{ value: customStyle.light.primaryIconTextColor, path: 'light.primaryIconTextColor' }]}
                      />

                      {/* cancelButtonBackgroundColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="CancelButtonBackgroundColor"
                        values={[{ value: customStyle.light.cancelButtonBackgroundColor, path: 'light.cancelButtonBackgroundColor' }]}
                      />
                      {/* backgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="BackgroundColors"
                        values={[
                          {
                            value: customStyle.light.backgroundColors[0],
                            path: 'light.backgroundColors.0',
                          },
                          {
                            value: customStyle.light.backgroundColors[1][0][0],
                            path: 'light.backgroundColors.1.0.0',
                          },
                          {
                            value: customStyle.light.backgroundColors[1][0][1],
                            path: 'light.backgroundColors.1.0.1',
                          },
                          {
                            value: customStyle.light.backgroundColors[1][1][0],
                            path: 'light.backgroundColors.1.1.0',
                          },
                          {
                            value: customStyle.light.backgroundColors[1][1][1],
                            path: 'light.backgroundColors.1.1.1',
                          },
                        ]}
                      />
                      {/* messageColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="MessageColors"
                        values={[
                          {
                            value: customStyle.light.messageColors[0],
                            path: 'light.messageColors.0',
                          },
                          {
                            value: customStyle.light.messageColors[1],
                            path: 'light.messageColors.1',
                          },
                        ]}
                      />
                      {/* borderGlowColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="BorderGlowColors"
                        values={[
                          {
                            value: customStyle.light.borderGlowColors[0],
                            path: 'light.borderGlowColors.0',
                          },
                          {
                            value: customStyle.light.borderGlowColors[1],
                            path: 'light.borderGlowColors.1',
                          },
                        ]}
                      />
                      {/* modalMaskBackgroundColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ModalMaskBackgroundColor"
                        values={[
                          {
                            value: customStyle.light.modalMaskBackgroundColor,
                            path: 'light.modalMaskBackgroundColor',
                          },
                        ]}
                      />
                      {/* primaryTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryTextColor"
                        values={[{ value: customStyle.light.primaryTextColor, path: 'light.primaryTextColor' }]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: 'Dark',
                key: TabKey.Dark,
                children: (
                  <div className="tab-content">
                    <div className="color-list">
                      {/* ColorAccent */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorAccent"
                        values={[{ value: customStyle.dark.colorAccent, path: 'dark.colorAccent' }]}
                      />
                      {/* ColorPrimary */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorPrimary"
                        values={[{ value: customStyle.dark.colorPrimary, path: 'dark.colorPrimary' }]}
                      />
                      {/* primaryButtonTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryButtonTextColor"
                        values={[{ value: customStyle.dark.primaryButtonTextColor, path: 'dark.primaryButtonTextColor' }]}
                      />
                      {/* colorOnPrimary */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ColorOnPrimary"
                        values={[{ value: customStyle.dark.colorOnPrimary, path: 'dark.colorOnPrimary' }]}
                      />
                      {/* primaryButtonBackgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryButtonBackgroundColors"
                        values={[
                          {
                            value: customStyle.dark.primaryButtonBackgroundColors[0],
                            path: 'dark.primaryButtonBackgroundColors.0',
                          },
                          {
                            value: customStyle.dark.primaryButtonBackgroundColors[1],
                            path: 'dark.primaryButtonBackgroundColors.1',
                          },
                        ]}
                      />
                      {/* primaryIconButtonBackgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryIconButtonBackgroundColors"
                        values={[
                          {
                            value: customStyle.dark.primaryIconButtonBackgroundColors[0],
                            path: 'dark.primaryIconButtonBackgroundColors.0',
                          },
                          {
                            value: customStyle.dark.primaryIconButtonBackgroundColors[1],
                            path: 'dark.primaryIconButtonBackgroundColors.1',
                          },
                        ]}
                      />
                      {/* primaryIconTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryIconTextColor"
                        values={[{ value: customStyle.dark.primaryIconTextColor, path: 'dark.primaryIconTextColor' }]}
                      />
                      {/* cancelButtonBackgroundColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="CancelButtonBackgroundColor"
                        values={[{ value: customStyle.dark.cancelButtonBackgroundColor, path: 'dark.cancelButtonBackgroundColor' }]}
                      />
                      {/* backgroundColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="BackgroundColors"
                        values={[
                          {
                            value: customStyle.dark.backgroundColors[0],
                            path: 'dark.backgroundColors.0',
                          },
                          {
                            value: customStyle.dark.backgroundColors[1][0][0],
                            path: 'dark.backgroundColors.1.0.0',
                          },
                          {
                            value: customStyle.dark.backgroundColors[1][0][1],
                            path: 'dark.backgroundColors.1.0.1',
                          },
                          {
                            value: customStyle.dark.backgroundColors[1][1][0],
                            path: 'dark.backgroundColors.1.1.0',
                          },
                          {
                            value: customStyle.dark.backgroundColors[1][1][1],
                            path: 'dark.backgroundColors.1.1.1',
                          },
                        ]}
                      />
                      {/* messageColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="MessageColors"
                        values={[
                          {
                            value: customStyle.dark.messageColors[0],
                            path: 'dark.messageColors.0',
                          },
                          {
                            value: customStyle.dark.messageColors[1],
                            path: 'dark.messageColors.1',
                          },
                        ]}
                      />
                      {/* borderGlowColors */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="BorderGlowColors"
                        values={[
                          {
                            value: customStyle.dark.borderGlowColors[0],
                            path: 'dark.borderGlowColors.0',
                          },
                          {
                            value: customStyle.dark.borderGlowColors[1],
                            path: 'dark.borderGlowColors.1',
                          },
                        ]}
                      />
                      {/* modalMaskBackgroundColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="ModalMaskBackgroundColor"
                        values={[
                          {
                            value: customStyle.dark.modalMaskBackgroundColor,
                            path: 'dark.modalMaskBackgroundColor',
                          },
                        ]}
                      />
                      {/* primaryTextColor */}
                      <ColorItem
                        activeKey={activeKey}
                        customStyle={customStyle}
                        setCustomStyle={setCustomStyle}
                        label="PrimaryTextColor"
                        values={[{ value: customStyle.dark.primaryTextColor, path: 'dark.primaryTextColor' }]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: 'JSON',
                key: TabKey.Json,
                children: (
                  <div className="json-content tab-content">
                    <CopyToClipboard
                      text={JSON.stringify(customStyle, null, 2)}
                      onCopy={() => {
                        message.success(t('copied_to'));
                      }}
                    >
                      <div className="copy-wrap">
                        <MIcon name="icon_14" className="copy-icon" />
                      </div>
                    </CopyToClipboard>

                    {<JSONPretty id="json-pretty" data={customStyle}></JSONPretty>}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

const Setting = () => {
  return (
    <>
      <Popover content={Content} title="CustomStyle" trigger="click" placement={'left'} arrowPointAtCenter={true} overlayClassName="custom-style-popover">
        <MIcon name="icon_23" size={40} className="custom-style-icon" />
      </Popover>
    </>
  );
};
export default Setting;
