import tinycolor from 'tinycolor2';

// 获取 :root css 变量值
const getVariable = (name: string, el = document.body) => {
  return getComputedStyle(el).getPropertyValue(name);
};

// 设置 :root css 变量值
const setVariable = (name: string, value: string, el = document.body) => {
  el.style.setProperty(name, value);
};

export const addStyleContent = (css: string, key: string = 'custom-themes') => {
  const style = document.createElement('style');
  style.innerHTML = css;
  document.head.appendChild(style);
  style.setAttribute('data-key', key);

  const styles = document.querySelectorAll(`[data-key="${key}"]`);
  if (styles.length > 1) {
    styles.forEach((style, index) => {
      if (index !== styles.length - 1) {
        style.remove();
      }
    });
  }
};

export const removeStyleElement = (key: string = 'custom-themes') => {
  const styles = document.querySelectorAll(`[data-key="${key}"]`);
  styles.forEach((style) => {
    style.remove();
  });
};

export const createCustomThemes = (data: any) => {
  // dark: {
  //   colorAccent: '#7DD5F9', // 高光颜色  例：dark模式的粉色，
  //   colorPrimary: '#21213a', // 主要内容背景颜色  例：页面内容背景色
  //   colorOnPrimary: '#171728', // 信息面板背景颜色  例：首页信息块背景色 , switch chain list 背景色
  //   primaryButtonBackgroundColors: ['#5ED7FF', '#E89DE7'], // 主要按钮颜色
  //   primaryButtonTextColor: '#0A1161', // 主要按钮字体颜色
  //   cancelButtonBackgroundColor: '#666666', // 取消按钮颜色
  //   backgroundColors: [
  //     '#14152e',
  //     [
  //       ['#e6b1f766', '#e6b1f700'],
  //       ['#7dd5f94d', '#7dd5f900'],
  //     ],
  //   ],
  //   messageColors: ['#7DD5F9', '#ed5d51'], // 消息背景颜色 [0:成功消息, 1:错误消息]
  //   borderGlowColors: ['#7bd5f940)', '#323233'], // 边框发光颜色 [0:亮色, 1:主色,]
  //   modalMaskBackgroundColor: '#141430b3', // 模态框遮罩层背景颜色
  // },

  try {
    // @ts-ignore
    let customStyle = window.__appParams?.customStyle || {};

    const { light, dark } = data;

    Object.assign(customStyle, data);

    window.storage.setItem('customStyle', Buffer.from(JSON.stringify(customStyle)).toString('base64'));

    const styles = {
      dark: [] as string[],
      light: [] as string[],
    };

    let {
      colorAccent,
      colorPrimary,
      colorOnPrimary,
      primaryButtonBackgroundColors,
      primaryButtonTextColor,
      cancelButtonBackgroundColor,
      backgroundColors,
      messageColors,
      borderGlowColors,
      modalMaskBackgroundColor,
      primaryIconButtonBackgroundColors,
      primaryIconTextColor,
      primaryTextColor,
    } = dark || {};

    let {
      colorAccent: lightColorAccent,
      colorPrimary: lightColorPrimary,
      colorOnPrimary: lightColorOnPrimary,
      primaryButtonBackgroundColors: lightPrimaryButtonBackgroundColors,
      primaryButtonTextColor: lightPrimaryButtonTextColor,
      cancelButtonBackgroundColor: lightCancelButtonBackgroundColor,
      backgroundColors: lightBackgroundColors,
      messageColors: lightMessageColors,
      borderGlowColors: lightBorderGlowColors,
      modalMaskBackgroundColor: lightModalMaskBackgroundColor,
      primaryIconButtonBackgroundColors: lightPrimaryIconButtonBackgroundColors,
      primaryIconTextColor: lightPrimaryIconTextColor,
      primaryTextColor: lightPrimaryTextColor,
    } = light || {};

    {
      if (primaryTextColor) {
        styles.dark.push(`--text-color: ${primaryTextColor}`);
      }
    }

    {
      if (lightPrimaryTextColor) {
        styles.light.push(`--text-color: ${lightPrimaryTextColor}`);
      }
    }

    {
      if (colorAccent) {
        styles.dark.push(`--accent-color: ${colorAccent}`);
      }

      if (lightColorAccent) {
        styles.light.push(`--accent-color: ${lightColorAccent}`);
      }
    }
    {
      if (primaryIconButtonBackgroundColors) {
        // dark
        if (primaryIconButtonBackgroundColors.length > 1 && primaryIconButtonBackgroundColors[0] != primaryIconButtonBackgroundColors[1]) {
          styles.dark.push(`--primaryIconButtonBackgroundColor: linear-gradient(0deg,${primaryIconButtonBackgroundColors[1]},${primaryIconButtonBackgroundColors[0]})`);
          styles.dark.push(`--primaryIconButtonTextColor: var(--text-color-1)`); // 高强调色
        } else if (primaryIconButtonBackgroundColors.length == 1) {
          const color = primaryIconButtonBackgroundColors[0].lighten(15).toHexString();
          styles.dark.push(`--primaryIconButtonBackgroundColor: ${color}`);
        }
      }
      if (lightPrimaryIconButtonBackgroundColors) {
        // light
        if (lightPrimaryIconButtonBackgroundColors.length > 1 && lightPrimaryIconButtonBackgroundColors[0] != lightPrimaryIconButtonBackgroundColors[1]) {
          styles.light.push(`--primaryIconButtonBackgroundColor: linear-gradient(0deg,${lightPrimaryIconButtonBackgroundColors[1]},${lightPrimaryIconButtonBackgroundColors[0]})`);
          styles.dark.push(`--primaryIconButtonTextColor: var(--text-color-1)`); // 高强调色
        } else if (lightPrimaryIconButtonBackgroundColors.length == 1) {
          const color = lightPrimaryIconButtonBackgroundColors[0].darken(15).toHexString();
          styles.light.push(`--primaryIconButtonBackgroundColor: ${color}`);
        }
      }
    }

    {
      if (colorPrimary) {
        styles.dark.push(`--content-background-color: ${colorPrimary}`);
        styles.dark.push(`--bg-color-4: ${colorPrimary}`);
        styles.dark.push(`--background-color-1: ${tinycolor(colorPrimary).lighten(3).toHexString()}`);
        styles.dark.push(`--input-disabled-bg-color: ${tinycolor(colorPrimary).lighten(3).toHexString()}`);
        styles.dark.push(`--border-color-0: ${tinycolor(colorPrimary).lighten(14).toHexString()}`);
        styles.dark.push(`--border-color-1: ${tinycolor(colorPrimary).lighten(6).toHexString()}`);
        styles.dark.push(`--border-color-3: ${tinycolor(colorPrimary).lighten(6).toHexString()}`);
        styles.dark.push(`--input-border-color: ${tinycolor(colorPrimary).lighten(6).toHexString()}`);
        styles.dark.push(`--input-bg-color: ${tinycolor(colorPrimary).lighten(4).toHexString()}`);
        styles.dark.push(`--shadow-color-2: ${tinycolor(colorPrimary).darken(80).setAlpha(0.25).toRgbString()}`); // 透明度 0.2
        styles.dark.push(`--bg-color-5: ${tinycolor(colorPrimary).lighten(10).toHexString()}`);
        styles.dark.push(`--skeleton-color-1: ${tinycolor(colorPrimary).lighten(8).toHexString()}`);
        styles.dark.push(`--icon-bg-color-1: ${tinycolor(colorPrimary).lighten(8).toHexString()}`);
        styles.dark.push(`--icon-bg-color-3: ${tinycolor(colorPrimary).lighten(16).toHexString()}`);
        styles.dark.push(`--bg-color-6: ${tinycolor(colorPrimary).lighten(5).toHexString()}`);
      }

      if (lightColorPrimary) {
        styles.light.push(`--content-background-color: ${lightColorPrimary}`);
        styles.light.push(`--bg-color-4: ${lightColorPrimary}`);
        styles.light.push(`--background-color-1: ${tinycolor(lightColorPrimary).darken(2).toHexString()}`);
        styles.light.push(`--input-disabled-bg-color: ${tinycolor(lightColorPrimary).darken(3).toHexString()}`);
        styles.light.push(`--border-color-0: ${tinycolor(lightColorPrimary).darken(14).toHexString()}`);
        styles.light.push(`--border-color-1: ${tinycolor(lightColorPrimary).darken(6).toHexString()}`);
        styles.light.push(`--border-color-3: ${tinycolor(lightColorPrimary).darken(6).toHexString()}`);
        styles.light.push(`--input-border-color: ${tinycolor(lightColorPrimary).darken(6).toHexString()}`);
        styles.light.push(`--input-bg-color: ${tinycolor(lightColorPrimary).darken(2).toHexString()}`);
        styles.light.push(`--shadow-color-2: ${tinycolor(lightColorPrimary).darken(40).setAlpha(0.2).toRgbString()}`); // 透明度 0.2
        styles.light.push(`--bg-color-5: ${tinycolor(lightColorPrimary).darken(8).toHexString()}`);
        styles.light.push(`--skeleton-color-1: ${tinycolor(lightColorPrimary).darken(8).toHexString()}`);
        styles.light.push(`--icon-bg-color-1: ${tinycolor(lightColorPrimary).lighten(20).toHexString()}`);
        styles.light.push(`--icon-bg-color-3: ${tinycolor(lightColorPrimary).darken(16).toHexString()}`);
        styles.light.push(`--bg-color-6: ${tinycolor(lightColorPrimary).darken(2).toHexString()}`);
      }
    }

    {
      if (primaryButtonBackgroundColors) {
        styles.dark.push(`--primaryButtonBackgroundColor: linear-gradient(271deg,${primaryButtonBackgroundColors[1]},${primaryButtonBackgroundColors[0]})`);
      }

      if (lightPrimaryButtonBackgroundColors) {
        styles.light.push(`--primaryButtonBackgroundColor: linear-gradient(271deg,${lightPrimaryButtonBackgroundColors[1]},${lightPrimaryButtonBackgroundColors[0]})`);
      }
    }

    {
      if (primaryButtonTextColor) {
        styles.dark.push(`--primaryButtonTextColor: ${primaryButtonTextColor}`);
      }

      if (lightPrimaryButtonTextColor) {
        styles.light.push(`--primaryButtonTextColor: ${lightPrimaryButtonTextColor}`);
      }
    }

    {
      if (cancelButtonBackgroundColor) {
        styles.dark.push(`--cancelButtonBackgroundColor: ${cancelButtonBackgroundColor}`);
      }

      if (lightCancelButtonBackgroundColor) {
        styles.light.push(`--cancelButtonBackgroundColor: ${lightCancelButtonBackgroundColor}`);
      }
    }

    {
      try {
        if (backgroundColors) {
          const [backgroundColor0, backgroundColor1] = backgroundColors || [];
          if (backgroundColor0) {
            styles.dark.push(`--app-background-color: ${backgroundColor0}`);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    {
      try {
        if (lightBackgroundColors) {
          const [backgroundColor0, backgroundColor1] = lightBackgroundColors || [];
          if (backgroundColor0) {
            styles.light.push(`--app-background-color: ${backgroundColor0}`);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    {
      if (messageColors) {
        styles.dark.push(`--message-success-color: ${messageColors[0]}`);
        styles.dark.push(`--message-error-color: ${messageColors[1]}`);
      }

      if (lightMessageColors) {
        styles.light.push(`--message-success-color: ${lightMessageColors[0]}`);
        styles.light.push(`--message-error-color: ${lightMessageColors[1]}`);
      }
    }

    {
      if (modalMaskBackgroundColor) {
        styles.dark.push(`--modal-mask-background-color: ${modalMaskBackgroundColor}`);
      }

      if (lightModalMaskBackgroundColor) {
        styles.light.push(`--modal-mask-background-color: ${lightModalMaskBackgroundColor}`);
      }
    }

    const styleContent = `
    html.theme-dark,
    body.theme-dark{
      ${styles.dark.join(';\n')}
    }
    html.theme-light,
    body.theme-light{
      ${styles.light.join(';\n')}
    }
  `;
    addStyleContent(styleContent);
  } catch (error) {
    console.log(error);
  }
};
