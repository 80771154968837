import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { EvmService } from '@particle-network/wallet-core';
import { useDeepCompareEffect, useRequest } from 'ahooks';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';

export const cacheKey = 'uaActivityList';

const useUniversaActivityList = () => {
  const router = useRouter();

  const { selectedGlobalChain, walletAddress, setUniversalTransactionList, setUniversalTransactionLoading } = useUniversalAccount();

  const [loading, setLoading] = useState(true);

  const hasNextPageRef = useRef(true);

  const [currentPageIndex, setCurrentPageIndex] = useState(1);

  /**
   * 是否是请求中
   */
  const requestRef = useRef(false);

  const { data, run, cancel } = useRequest(
    (pageIndex = 1) => {
      return (window?.__webWalletData?.particleWallet as EvmService).getLPCrossChainUserOperations(pageIndex, 10);
    },
    {
      manual: true,
      onSuccess: (result) => {
        const { hasNextPage } = result || {};
        if (currentPageIndex > 1) {
          hasNextPageRef.current = hasNextPage;
        }
      },
      onFinally: () => {
        requestRef.current = false;
        setLoading(false);
      },
    }
  );

  const { list = [] } = data || {};

  const newList = useMemo(() => {
    if (!selectedGlobalChain) {
      return [];
    } else {
      let allData = JSON.parse((window as any)[cacheKey] || '{}');
      let { list: _list = [], isAllVisited } = allData[walletAddress] || {};

      const oList = [...list];

      if (oList.length) {
        for (let index = 0; index < oList.length; index++) {
          const { id } = oList[index] as any;

          if (!_list.find((item: any) => item.id === id)) {
            _list.push(oList[index]);
          } else {
            _list = _list.map((item: any) => {
              if (item.id === id) {
                return oList[index];
              }
              return item;
            });
          }
        }

        _list = _list.sort((a: any, b: any) => new Date(b.dateValue).getTime() - new Date(a.dateValue).getTime());
        allData[walletAddress] = {
          list: cloneDeep(_list),
          updatedAt: new Date().getTime(),
          isAllVisited: isAllVisited || !hasNextPageRef.current,
        };
        (window as any)[cacheKey] = JSON.stringify(allData);
      }
      return _list;
    }
  }, [selectedGlobalChain, walletAddress, list, hasNextPageRef.current]);

  useDeepCompareEffect(() => {
    setUniversalTransactionList(newList);
    setUniversalTransactionLoading(loading && !newList.length);
  }, [newList, loading]);

  const getScrollDom = () => {
    return document.querySelector('.scroll-content-1') || document.querySelector('.ant-tabs-content-holder');
  };

  useEffect(() => {
    const scrollHandler = () => {
      const scrollDom = getScrollDom();
      if (!scrollDom) {
        return;
      }
      if (scrollDom.scrollTop + scrollDom.clientHeight + 300 >= scrollDom.scrollHeight) {
        if (hasNextPageRef.current && !requestRef.current) {
          requestRef.current = true;
          setCurrentPageIndex((prev) => {
            run(prev + 1);
            return prev + 1;
          });
        }
      }
    };

    if (walletAddress && selectedGlobalChain && ['/', '/tokenDetail', '/uaTransactionDetail'].includes(router.pathname)) {
      cancel && cancel();
      run();

      setTimeout(() => {
        const scrollDom = getScrollDom();
        console.log('scrollDom', scrollDom);
        if (scrollDom) {
          scrollDom.addEventListener('scroll', scrollHandler);
        }
      }, 300);
    } else {
      setTimeout(() => {
        cancel();
      });
    }
    return () => {
      cancel();
      const scrollDom = getScrollDom();
      scrollDom && scrollDom.removeEventListener('scroll', scrollHandler);
    };
  }, [router.pathname, selectedGlobalChain, walletAddress]);

  useEffect(() => {
    // @ts-ignore
    window.__universalTransactionRefresh__ = () => {
      run(1);
    };
  }, []);
};

export default useUniversaActivityList;
