export enum NftListType {
  Market = 'Market',
  MintNft = 'MintNft',
  MyNft = 'MyNft',
  SettleAccounts = 'SettleAccounts',
  UncompletedTransaction = 'UncompletedTransaction',
}

export enum PageType {
  Default = 'Default',
  Index = 'Index',
  Send = 'Send',
  Swap = 'Swap',
  NftSend = 'NftSend',
  BUY2 = 'BUY2',
  NftBatchSend = 'NftBatchSend',
  TokenBatchSend = 'TokenBatchSend',
  TokenDetail = 'TokenDetail',
  Bridge = 'Bridge',
}

export interface StartScanOption {
  hasCamera: boolean;
  start: boolean;
  initcomplete?: boolean;
}

export interface Storage {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
}
