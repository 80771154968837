import zh from './locales/zh.json';
import en from './locales/en.json';
import tc from './locales/tc.json';
import jp from './locales/jp.json';
import kr from './locales/kr.json';

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     lng: 'en',
//     fallbackLng: 'en',
//     resources: {
//       en: {
//         translation: en,
//       },
//       'zh-CN': {
//         translation: zh,
//       },
//       'zh-TW': {
//         translation: tc,
//       },
//       ja: {
//         translation: jp,
//       },
//       ko: {
//         translation: kr,
//       },
//     },
//     debug: false,
//     interpolation: {
//       escapeValue: false,
//     },
//   });

// export default i18n;

const resources = {
  en: {
    translation: en,
  },
  'zh-CN': {
    translation: zh,
  },
  'zh-TW': {
    translation: tc,
  },
  'zh-HK': {
    translation: tc,
  },
  ja: {
    translation: jp,
  },
  ko: {
    translation: kr,
  },
};
export default resources;
