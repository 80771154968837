import { useUniversalAccount } from '@/context/UniversalAccountContext';
import { EvmService, fiatAmountFormat } from '@particle-network/wallet-core';
import { useRequest } from 'ahooks';
import BigNumber from 'bignumber.js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { errorHandle } from '../utils';

const useUniversalTokens = () => {
  const { selectedGlobalChain, walletAddress, setUaBalance, setUniversalTokens, setUniversalTokensLoading } = useUniversalAccount();

  const router = useRouter();

  const cacheKey = `universalTokens_${walletAddress || ''}`.toLowerCase();

  const [loading, setLoading] = useState(true);

  const { data, run, cancel, refresh } = useRequest(
    () => {
      if (!window.__webWalletData?.particleWallet || !walletAddress) {
        return Promise.resolve([]);
      }
      return (window.__webWalletData?.particleWallet as EvmService)?.getUniversalTokens?.(selectedGlobalChain);
    },
    {
      cacheKey,
      pollingInterval: 10000,
      manual: true,
      onError: (error: any) => {
        errorHandle(error);
      },
      onFinally: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    setUniversalTokens(data || []);
  }, [data]);

  useEffect(() => {
    setUniversalTokensLoading(data?.length ? false : loading);
  }, [data, loading]);

  useEffect(() => {
    if (walletAddress && selectedGlobalChain && ['/', '/choiceTokenList', '/tokenDetail'].includes(router.pathname)) {
      cancel && cancel();
      run();
    } else {
      setTimeout(() => {
        cancel();
      });
    }
    return () => {
      cancel();
    };
  }, [router.pathname, selectedGlobalChain, walletAddress]);

  useEffect(() => {
    if (data?.length) {
      setUaBalance(
        fiatAmountFormat(
          data.reduce((acc, token: any) => {
            return acc.plus(new BigNumber(token.price));
          }, new BigNumber(0))
        )
      );
    }
    // @ts-ignore
    window.__universalTokens__ = data;
  }, [data]);
};

export default useUniversalTokens;
