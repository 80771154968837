import { useEffect, useState } from 'react';

type DeviceType = 'safari' | 'chrome' | 'firefox' | 'ie' | 'edge' | 'opera' | 'unknown';

const getDevice = () => {
  let deviceType: DeviceType;

  const ua = navigator.userAgent.toLowerCase();
  // 判断是否是获取浏览器
  if (ua.indexOf('safari') > -1) {
    if (ua.indexOf('chrome') > -1) {
      deviceType = 'chrome';
    } else if (ua.indexOf('firefox') > -1 || ua.indexOf('fxios') > -1) {
      deviceType = 'firefox';
    } else if (ua.indexOf('opera') > -1) {
      deviceType = 'opera';
    } else if (ua.indexOf('msie') > -1) {
      deviceType = 'ie';
    } else if (ua.indexOf('edge') > -1) {
      deviceType = 'edge';
    } else {
      deviceType = 'safari';
    }
  } else {
    deviceType = 'unknown';
  }
  return deviceType;
};

const useDevice = () => {
  const [device, setDevice] = useState<DeviceType>();
  useEffect(() => {
    const device: DeviceType = getDevice();
    setDevice(device);
  }, []);

  return device;
};

export default useDevice;
